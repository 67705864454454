export const removeItemFromArray = <ArrType>(array: ArrType[], itemToRemove: ArrType) => {
  const index = array.indexOf(itemToRemove)
  const arrayClone = [...array]
  
  // remove item if found
  if (index > -1) {
    arrayClone.splice(index, 1)
  }

  return arrayClone
}
