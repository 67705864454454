import { useFormik } from 'formik'
import { Alert } from '../icons'
import TextInput from '../TextInput'
import styles from './DiscountForm.module.scss'
import Loader from '../Loader'
import useSubmitDiscountCode from '../../hooks/useSubmitDiscountCode'

interface IDiscountCodeFormValue {
  discountCode: string | undefined;
}

const validate = (values: IDiscountCodeFormValue) => {
  const errors: GenericObject = {}
  if (!values.discountCode) {
    errors.discountCode = 'Required field'
  }

  return errors
}

const DiscountForm = () => {
  const formik = useFormik({
    initialValues: {
      discountCode: '',
    },
    validate,
    onSubmit: values => {
      submitHandler(values)
    },
  })

  const {
    loading,
    error: fetchError,
    setError,
    submitDiscountCode,
  } = useSubmitDiscountCode({
    'discountCode': formik.values.discountCode, // Data payload
  })

  const discountServiceError = fetchError as GenericObject

  const submitHandler = (values: IDiscountCodeFormValue) => {
    submitDiscountCode()
  }
  const isInvalidCodeError = fetchError ? true : false
  const hasError = Object.keys(formik.errors).length > 0 || isInvalidCodeError

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e)
    setError(false)
  }

  return (
    <div>
      <h5 className={styles.title}>Enter discount code</h5>
      <form
        className={styles.form}
        onSubmit={formik.handleSubmit}
        data-testid='discount-form'
      >
        <Loader loaderSize={35} lineSize={5} isLoading={loading} />
        <div className={styles.fieldWrapper}>
          <TextInput
            name='discountCode'
            id='discountCode'
            value={formik.values.discountCode}
            onChange={changeHandler}
            // Check if formik error object has any properties.
            isError={hasError}
            errorMsg={formik.errors.discountCode}
            testId='discount-input'
            autoComplete='off'
            className={styles.discountFormField}
          />

          <button
            type='submit'
            className={styles.submitBtn}
            data-testid='discount-submit-button'
          >
            Apply
          </button>

          {(hasError) &&
            <div className={styles.errorIcon}>
              <Alert width={32} height={32} />
            </div>
          }
        </div>
        {discountServiceError?.response?.status === 400 &&
          <div className={styles.invalidCodeErrorMsg} data-testid='discount-error-message'>
            <p>The discount code entered is not valid. Please try again.</p>
          </div>
        }

        {(discountServiceError && discountServiceError?.response?.status !== 400) && 
          <div className={styles.invalidCodeErrorMsg} data-testid='discount-service-error-message'>
            <p>We're unable to verify this discount code right now. Please try again later.</p>
          </div>
        }
      </form>
    </div>
  )
}

export default DiscountForm