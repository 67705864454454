import { useEffect, useRef } from 'react'
import NotificationCard from '../NotificationCard'
import '@adyen/adyen-web/dist/adyen.css'
import styles from './PaymentMethods.module.scss'
import Core from '@adyen/adyen-web/dist/types/core/core'

interface IComponentProps {
  paymentMethod: string;
  adyenCheckout: Core;
  error?: string | null;
}

const PaymentMethod = ({
  paymentMethod,
  adyenCheckout,
  error = null,
}: IComponentProps) => {
  const paymentComponentContainer = useRef<HTMLDivElement>(null)

  const setupApplePay = () => {
    const applePayComponent = adyenCheckout.create('applepay')

    // Only render if Apple Pay is available
    applePayComponent
      .isAvailable()
      .then(() => {
        applePayComponent.mount(paymentComponentContainer?.current as HTMLElement)
      })
      .catch(e => {
        //Apple Pay is not available
        console.error(e)
      })
  }

  const setupPayPal = () => {
    const paypalConfiguration = {
      blockPayPalPayLaterButton: true,
      blockPayPalCreditButton: true,
    }

    adyenCheckout.create(paymentMethod, paypalConfiguration).mount(paymentComponentContainer?.current as HTMLElement)
  }

  const renderWebComponent = () => {
    if (!paymentComponentContainer?.current) {
      console.log('No component container exists. This is needed to mount the Adyen component')
      return
    }

    // Handle Apple Pay
    if (paymentMethod === 'applepay' && paymentComponentContainer?.current) {
      setupApplePay()
      return
    }

    // Handle PayPal
    if (paymentMethod === 'paypal' && paymentComponentContainer?.current) {      
      setupPayPal()
      return
    }

    // Create an instance of the Component and mount it to the container you created.
    adyenCheckout.create(paymentMethod).mount(paymentComponentContainer?.current)
  }

  useEffect(() => {
    renderWebComponent()
  }, [])

  return (
    <div>
      {error !== null &&
        <div className={styles.errorContainer}>
          <NotificationCard type='danger' heading='Error'>
            <p>An error occurred with your payment: {error}</p>
          </NotificationCard>
        </div>
      }
      <div className={styles.paymentMethod} ref={paymentComponentContainer}></div>
    </div>
  )
}

export default PaymentMethod