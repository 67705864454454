import styles from './FilterPanel.module.scss'
import { IFiltersApplied, IFilterOption } from './types'
import FilterOption from './FilterOption'
import capitalizeString from '../../utils/capitalize'
import trainerTypes from '../../constants/trainer-types'
import useFilters from '../../hooks/useFilters'
import { useContext, useState } from 'react'
import { Chevron2Down, Chevron2Up } from '../icons'
import { filterContext } from '../PersonalTrainerLister/filterContext'

interface IComponentProps {
  heading: string;
  filterOptions: any[];
  ptAttributeBeingFiltered: keyof IFiltersApplied;
  unfilteredPtList?: PersonalTrainerListDataType;
}

// Adding a PT attribute here will make it static (will not update as user applies filters)
const STATIC_FILTERS = ['level']

const FilterPanel = ({
  heading,
  filterOptions,
  ptAttributeBeingFiltered,
  unfilteredPtList,
} : IComponentProps) => {
  const filtContext = useContext(filterContext)
  const { addRemoveFilter } = useFilters()
  const [isPanelOpen, setIsPanelOpen] = useState(true)

  const togglePanel = () => { setIsPanelOpen(!isPanelOpen) }
  
  const changeFilters = (option: string) => {
    addRemoveFilter(ptAttributeBeingFiltered, option)
  }

  const isStaticFilter = STATIC_FILTERS.some(staticFilter => staticFilter === ptAttributeBeingFiltered)

  // Used to display the number of PTs available for each level only
  const countLevelInFilteredSpecialities = (level: string) => {
    // First get all of this level in the unfiltered PT list
    const allOfLevelInPtList = unfilteredPtList?.filter(pt => pt.level === level)
    
    if (!allOfLevelInPtList || allOfLevelInPtList.length < 1) {
      return 0
    }

    const listOfPTForLevel = allOfLevelInPtList.filter(pt => {
      const ptSpecialities = pt.specialities
      
      // Current filtered specialities
      const filteredSpeciality = filtContext?.appliedFilters.specialities ?? []
      
      // Create an array of specialities matched between pt.specialities and filtered specialities
      const filteredSpecialitiesFoundInPt = ptSpecialities.filter(ptSpec => {
        // Loop filtered specialities and find each one in the PT's specialities array.
        const hasFilteredSpeciality = filteredSpeciality?.some(filteredSpec => {
          // Convert both strings to lowercase to ensure that the strings match.
          return ptSpec.toLowerCase() === filteredSpec.toLowerCase()
        })
        
        return hasFilteredSpeciality
      })

      return filteredSpeciality.length > 0 ? filteredSpeciality.length === filteredSpecialitiesFoundInPt.length : true
    })

    return listOfPTForLevel.length
  }

  const sortFiltersCompare = (a: IFilterOption, b: IFilterOption) => {
    if (a.name > b.name) {
      return 1
    }

    if (b.name > a.name) {
      return -1
    }

    return 0
  }

  const filtersSorted = Array.from(filterOptions).sort(sortFiltersCompare)

  return (
    <div className={styles.filterPanel} data-testid={`filter-panel-${ptAttributeBeingFiltered}`}>
      <h5 className={`${styles.filterPanelHeadingWrapper} noMargin`}>
        <button className={styles.filterPanelHeading} onClick={togglePanel}>
          <div className={styles.filterPanelHeadingText}>{ heading }</div>
          <div>
            {!isPanelOpen &&
              <Chevron2Down width={28} height={28} className={styles.filterPanelHeadingIcon} />
            }
            {isPanelOpen &&
              <Chevron2Up width={28} height={28} className={styles.filterPanelHeadingIcon} />
            }
          </div>
        </button>
      </h5>
      {isPanelOpen &&
        <ul className={`${styles.filtersContainer} noStyles`}>
          {isStaticFilter && 
          <>
            <li className={styles.filterOptionContainer}>
              <FilterOption 
                label={trainerTypes['normal']}
                option={'normal'}
                count={countLevelInFilteredSpecialities('normal')}
                changeFilters={changeFilters}
                ptAttributeBeingFiltered={ptAttributeBeingFiltered}
              />
            </li>
            <li>
              <FilterOption 
                label={trainerTypes['senior']}
                option={'senior'}
                count={countLevelInFilteredSpecialities('senior')}
                changeFilters={changeFilters}
                ptAttributeBeingFiltered={ptAttributeBeingFiltered}
              />
            </li>
          </>
          }
          {!isStaticFilter && filtersSorted.map(opt => {
            return (
              <li key={opt.name} className={styles.filterOptionContainer}>
                <FilterOption 
                  label={capitalizeString(opt.name)}
                  option={opt.name}
                  count={opt.count}
                  changeFilters={changeFilters}
                  ptAttributeBeingFiltered={ptAttributeBeingFiltered}
                />
              </li>
            )
          })}
          {!isStaticFilter && filtersSorted.length === 0 &&
            <div data-testid='no-filters-available'>No filters available.</div>
          }
        </ul>
      }
    </div>
  )
}

export default FilterPanel
