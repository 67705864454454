import * as React from "react";

const SvgChevronDown = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m71.625 85.125-5.25 5.25-31.5-31.5 5.25-5.25 31.5 31.5Zm38.25-31.5-31.5 31.5 5.25 5.25 31.5-31.5-5.25-5.25ZM69.75 93.75 75 99l5.25-5.25L75 88.5l-5.25 5.25Z"
      stroke={props.stroke || '#00A200'}
      fill={props.fill || '#00A200'}
    />
  </svg>
);

export default SvgChevronDown;
