import * as React from "react";

const SvgPaymentPaypal = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 26"
    width="1em"
    height="1em"
    {...props}
  >
    <path fill="#fff" d="M0 0h40v26H0z" />
    <path fill="#fff" d="M0 0h40v26H0z" />
    <path
      fill="#003086"
      d="M25.36 5.36c-.84-.95-2.34-1.35-4.27-1.35H15.5a.8.8 0 0 0-.79.67L12.4 19.44a.48.48 0 0 0 .47.56h3.45l.87-5.5-.03.17a.8.8 0 0 1 .79-.67h1.64c3.22 0 5.74-1.31 6.48-5.1l.05-.32a3.57 3.57 0 0 0-.75-3.22"
    />
    <path
      fill="#003086"
      d="M25.36 5.36c-.84-.95-2.34-1.35-4.27-1.35H15.5a.8.8 0 0 0-.79.67L12.4 19.44a.48.48 0 0 0 .47.56h3.45l.87-5.5-.03.17a.8.8 0 0 1 .79-.67h1.64c3.22 0 5.74-1.31 6.48-5.1l.05-.32a3.57 3.57 0 0 0-.75-3.22"
    />
    <path
      fill="#002f85"
      d="M18.1 8.6a.7.7 0 0 1 .7-.6h4.38a9.15 9.15 0 0 1 1.45.11l.37.07.34.09.17.05a3.96 3.96 0 0 1 .6.26 3.57 3.57 0 0 0-.75-3.22c-.84-.95-2.34-1.35-4.27-1.35H15.5a.8.8 0 0 0-.79.67L12.4 19.44a.48.48 0 0 0 .47.56h3.45l.87-5.5.93-5.9z"
    />
    <path
      fill="#009bdd"
      d="m26.11 8.58-.05.32c-.74 3.79-3.26 5.1-6.48 5.1h-1.64a.8.8 0 0 0-.79.67L16.31 20l-.24 1.5a.42.42 0 0 0 .42.5h2.9a.7.7 0 0 0 .7-.6l.02-.15.55-3.47.04-.2a.7.7 0 0 1 .69-.58h.43c2.82 0 5.03-1.15 5.67-4.46a3.8 3.8 0 0 0-.58-3.35 2.78 2.78 0 0 0-.8-.61"
    />
    <path
      fill="#012069"
      d="M25.34 8.27 25 8.18a6.55 6.55 0 0 0-.37-.07 9.1 9.1 0 0 0-1.45-.1H18.8a.7.7 0 0 0-.7.59l-.92 5.9-.03.17a.8.8 0 0 1 .79-.67h1.64c3.22 0 5.74-1.31 6.48-5.1l.05-.32a3.98 3.98 0 0 0-.6-.26z"
    />
  </svg>
);

export default SvgPaymentPaypal;
