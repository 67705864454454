import * as React from "react";

const SvgWarning = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="gray"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M15.95 4.48 2.78 27.52h26.44L15.95 4.48Zm0 3.01 10.67 18.52H5.37L15.95 7.5v-.01Z" />
    <path d="m16.005 21.256-1.202 1.202 1.202 1.202 1.202-1.202-1.202-1.202ZM16.43 20.1c.34-1.8.44-3.3.44-7.16v-.29h-1.76v.27c0 3.86.11 5.37.44 7.17h.89l-.01.01Z" />
  </svg>
);

export default SvgWarning;
