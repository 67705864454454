import { ChangeEvent, ReactNode } from 'react'
import style from './Radio.module.scss'

interface IComponentProp {
  name: string;
  value: string;
  label?: string;
  children?: ReactNode;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  inputTestId?: string;
  testId?: string;
}

const Radio = ({
  name,
  value,
  label,
  children,
  onChange,
  checked = false,
  inputTestId,
  testId,
}: IComponentProp) => {
  return (
    <label className={style.root} data-testid={testId}>
      <input
        type="radio"
        name={name}
        value={value}
        className={style.input}
        onChange={onChange}
        checked={checked}
        data-testid={inputTestId}
      />
      <span className={style.radio}></span>
      {label &&
        <span className={style.label}>{label}</span>
      }
      {children &&
        <div>
          {children}
        </div>
      }
    </label>
  )
}

export default Radio