import * as React from "react";

const SvgCheckmark = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="m1 5 6 6L17 1" stroke={props.stroke || '#00A200'} strokeWidth={2} />
  </svg>
);

export default SvgCheckmark;
