import { ReactNode } from 'react'
import Portal from '../Portal'
import { Cross } from '../icons'
import style from './Modal.module.scss'

interface IComponentProps {
  isActive?: boolean;
  closeFunc?(): void;
  portalId?: string;
  children: ReactNode;
  isFullScreen?: boolean;
  title?: ReactNode;
  hasCornerCloseButton?: boolean;
  modalWidth?: string;
  className?: string;
  fullScreenWrapperClass?: string;
  testId?: string;
}

const Modal = ({
  isActive = false,
  closeFunc,
  portalId = 'portalRoot',
  isFullScreen = false,
  title,
  hasCornerCloseButton = true,
  children,
  modalWidth = '95%',
  className,
  fullScreenWrapperClass,
  testId,
}: IComponentProps) => {

  const fullScreenClass = isFullScreen ? style.fullScreen : ''

  const modalRootWidth = isFullScreen ? '100%' : modalWidth

  return (
    <Portal wrapperId={portalId}>
      {isActive &&
        <>
          {!fullScreenClass && 
            <>       
              <div
                className={`${style.root} ${fullScreenClass} ${className}`}
                style={{ width: modalRootWidth }}
                data-testid={testId}
              >
                <div className={style.modalHeader}>
                  <h3 className={style.title}>{title}</h3>
                  {hasCornerCloseButton &&
                    <button onClick={closeFunc} className={style.modalCloseBtn}>
                      <span><Cross fill="#00A200" width={20} height={20} /></span>
                    </button>
                  }
                </div>
                {children}
              </div>
              <div
                onClick={closeFunc}
                className={style.mask}
                data-testid='modal-mask'
              ></div>
            </>
          }

          {isFullScreen &&
            <div className={`${style.root} ${fullScreenClass} ${className}`} data-testid={testId}>
              <div className={fullScreenWrapperClass}>
                {hasCornerCloseButton && 
                  <button onClick={closeFunc} className={style.fullScreenCloseBtn}></button>
                }
                {children}
              </div>
            </div>
          }
        </>
      }
    </Portal>
  )
}

export default Modal