import * as React from "react";

const SvgClipboard = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 37 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.292 5.817V3.215h-5.356C22.867 1.538 20.775.492 18.5.5c-2.295.008-4.397 1.073-5.467 2.77H7.708v2.557H0V44.5h37V5.817h-7.708Zm-18.5.028h4.424l.327-.925c.385-1.088 1.576-1.838 2.935-1.847 1.358-.008 2.563.728 2.967 1.811l.336.907h4.427v2.597H10.792V5.845ZM3.083 8.403v33.521h30.834V8.388h-4.625v2.576H7.708v-2.56H3.083Zm6.805 8.516 2.18 1.821-2.18 1.822-2.18-1.822 2.18-1.82Zm2.18 9.439-2.18-1.822-2.18 1.822 2.18 1.82 2.18-1.82Zm-2.18 6.017 2.18 1.821-2.18 1.821-2.18-1.82 2.18-1.822Zm19.404-14.923H15.417v2.576h13.875v-2.576ZM15.417 25.18h13.875v2.576H15.417V25.18Zm13.875 7.728H15.417v2.576h13.875v-2.576Z"
      fill="#2EA836"
    />
  </svg>
);

export default SvgClipboard;
