import visaLogo from '../../assets/images/visa.png'
import mastercardLogo from '../../assets/images/mastercard.png'
import maestroLogo from '../../assets/images/maestro.png'
import amexLogo from '../../assets/images/amex.png'
import styles from './AcceptedCards.module.scss'

const AcceptedCards = () => (
  <div className={styles.acceptedCards}>
    <div className={styles.card}>
      <img
        src={visaLogo}
        className={styles.cardImage}
        alt='Visa accepted'
      />
    </div>
    <div className={styles.card}>
      <img
        src={mastercardLogo}
        className={styles.cardImage}
        alt='Mastercard accepted'
      />
    </div>
    <div className={`${styles.card} ${styles.amex}`}>
      <img
        src={amexLogo}
        className={styles.cardImage}
        alt='American Express accepted'
      />
    </div>
    <div className={styles.card}>
      <img
        src={maestroLogo}
        className={styles.cardImage}
        alt='Maestro accepted'
      />
    </div>
  </div>
)

export default AcceptedCards