import { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import Body from '../../components/Body'
import Button, { LinkButton } from '../../components/Button'
import Header from '../../components/Header'
import { Info, Location } from '../../components/icons'
import Modal from '../../components/Modal'
import useGetPeople from '../../hooks/useGetPeople'
import { getHomeGym } from '../../utils/getUserData'
import styles from './SelectAPersonalTrainer.module.scss'
import { FullPageLoader } from '../../components/Loader'
import PersonalTrainerLister from '../../components/PersonalTrainerLister'
import { appContext } from '../../context'
import LocationFinder from '../../components/LocationFinder'
import useGetPlaces from '../../hooks/useGetPlaces'
import MobileDropDownPanel from '../../components/MobileDropDownPanel/MobileDropDownPanel'
import URL from '../../constants/route-urls'
import useDataLayer from '../../hooks/useDataLayer'
import NotificationCard from '../../components/NotificationCard'
import { AxiosError } from 'axios'

interface IComponentProps {
  isLocationEditOpen?: boolean;
}

const SelectAPersonalTrainer = ({
  isLocationEditOpen = false,
}: IComponentProps) => {
  const context = useContext(appContext)
  const { dataLoadedEvent } = useDataLayer()
  const [isDifferentTypesPTModalOpen, setDifferentTypesPTModalOpen] = useState(false)
  let { urlGymId } = useParams() // Get gym id from URL param
  
  const toggleDifferentTypesPTModalOpen = () => {
    setDifferentTypesPTModalOpen(!isDifferentTypesPTModalOpen)
  }
  const locationId: string = urlGymId ?? getHomeGym() // Use url gym id or home gym from user data

  const {
    peopleData,
    error,
    loading,
    getPeopleData,
  } = useGetPeople(locationId)

  const {
    loading: currentLocationLoader,
  } = useGetPlaces(locationId)

  const [isLocationModalOpen, setLocationModalOpen] = useState(isLocationEditOpen)
  const toggleLocationModal = () => {
    setLocationModalOpen(!isLocationModalOpen)
  }

  useEffect(() => {
    if (locationId) {
      context?.setGymId(locationId)
    }
  }, [])

  useEffect(() => {
    getPeopleData()
    setLocationModalOpen(false)    
  }, [context?.gymId])
  
  useEffect(() => {
    if (
      context?.basket &&
      (context?.selectedGymData?.salesForceId === context?.gymId) &&
      context?.selectedGymData?.name
    ) {
      dataLoadedEvent({
        pageType: 'Personal trainer lister',
        pageName: 'Select Personal Trainer',
        pageUrl: `${window.location.origin}/${URL.SELECT_PERSONAL_TRAINER}`,
        pageTitle: 'Select a Personal Trainer',
      },
      {
        'action': 'select personal trainer',
        'step': 2,
      }, [{
        'serviceLine': 'Personal Trainer',
        'location': context?.selectedGymData?.name ?? '',
        'clubid': locationId,
      }])
    }
  }, [context?.basket, context?.selectedGymData?.name])

  const isPlacesAPIError = ((
    (
      context?.locationApiFailure?.response?.status >= 400 && 
      context?.locationApiFailure?.response?.status <= 599 &&
      context?.locationApiFailure?.response?.status !== 404
    )
  ) && !currentLocationLoader)

  return (
    <>
      <Helmet>
        <title>Select a Personal Trainer</title>
      </Helmet>
      <Header title="Select a personal trainer" step={1} />
      <Body>
        <>
          <div className={styles.titleContainer}>
            <h2 className={styles.title}>
              {context?.selectedGymData && !currentLocationLoader && <span data-testid='pt-select-heading'>Personal trainers at {context?.selectedGymData?.name}</span>}
              {!context?.selectedGymData && !currentLocationLoader && <span data-testid='pt-select-error-heading'>Your gym was not found</span>}
            </h2>

            {(!context?.locationApiFailure || (context?.locationApiFailure?.response?.status < 500 || context?.locationApiFailure?.response?.status > 599)) &&
              <div className={styles.locationEditCtaWrapper}>
                <LinkButton link={`/${URL.LOCATION_FINDER}`} className={styles.locationEditCta} buttonStyleType='text'>
                  <div className={styles.locationIcon}>
                    <Location height={24} width={24} />
                  </div>
                  <span className={styles.locationEditCtaText}>Change location</span>
                </LinkButton>
              </div>
            }

            {context?.locationApiFailure?.response?.status === 404 && 
              <div className={styles.locationErrorBox}>
                <NotificationCard heading="Sorry, we're experiencing some technical difficulties" type='danger'>
                  We're unable to find the gym you've selected. Please try again later or choose a different location.
                </NotificationCard>
              </div>
            }

            {isPlacesAPIError && 
              <div className={styles.locationErrorBox}>
                <NotificationCard heading='No personal trainers found' type='danger'>
                  Please try again later or contact your local gym to book personal training.
                </NotificationCard>
              </div>
            }
          </div>
          
          {!context?.locationApiFailure &&
            <>
              <div className={styles.bodyText}>
                <p>Our qualified personal trainers can tailor sessions and programmes to help you become fitter, healthier, and reach your personal goals faster.</p>
                <p>Browse through our trainers and their bios to find the perfect fit for your needs. We'll check the availability of your requested personal trainer and do our best to accommodate your preference.</p>
              </div>
          
              <div className={styles.dropDownWrapper}>
                <MobileDropDownPanel
                  headingIcon={<Info width={30} height={30} className={styles.ptTypeHeadingIconSvg} />}
                  heading={<h3 className={styles.ptTypeHeading}>What type of trainer is right for you?</h3>}
                >
                  <p><h5 className={styles.trainerType}>Wellbeing Personal Trainer</h5> are great for beginners, starting your fitness journey with engaging sessions and tailored programmes to keep you on track.</p>
                  <p><h5 className={styles.trainerType}>Senior Personal Trainer</h5> bring added knowledge and skills, perfect for achieving specific fitness goals.</p>
                </MobileDropDownPanel>
              </div>
            </>
          }

          <div className={styles.ptSelectionWrapper}>
            {/* If the People API fails */}
            {error instanceof AxiosError && error?.response?.status !== 404 &&
              <div className={styles.errorMessage}>
                <NotificationCard
                  heading='Personal trainers could not be received'
                  type='danger'
                >
                  Please try again later or contact your local gym to book personal training.
                </NotificationCard>
              </div>
            }

            <FullPageLoader text='Loading personal trainers...' isLoading={loading} />
            
            {/* If the PT list returned is empty */}
            {error instanceof AxiosError && error?.response?.status === 404 &&
              <NotificationCard
                heading='No personal trainers found'
                type='danger'
              >
                Please try again later or contact your local gym to book personal training.
              </NotificationCard>
            }

            {peopleData && peopleData?.data?.people?.length > 0 &&
              <PersonalTrainerLister
                gymId={locationId}
                ptList={peopleData?.data?.people}
              />
            }
              
          </div>                
        </>
      </Body>
      <Modal
        isFullScreen
        closeFunc={toggleDifferentTypesPTModalOpen}
        isActive={isDifferentTypesPTModalOpen}
      >
        <div className={styles.modalContent}>
          <h2>Different personal training styles</h2>

          <div className={styles.ptStylesModalContent}>
            <section className={styles.modalSection}>
              <h3 className={`bold ${styles.modalSectionTitle}`}>Rehab Specialist</h3>
              <p>Our rehabilitation specialists are focused on forming strong relationships with their clients. They set personalised goals and objectives, and as qualified experts our rehabilitation specialists will help rebuild key practical skills and improve everyday functionality to allow clients to reintegrate successfully back into society.</p>
            </section>

            <section className={styles.modalSection}>
              <h3 className={`bold ${styles.modalSectionTitle}`}>Senior Wellbeing Trainers</h3>
              <p>Ideal for more specific fitness and wellbeing needs.</p>
              <p>Senior Personal Trainers at Nuffield health are:</p>
              <ul>
                <li>Qualified in movement screening</li>
                <li>Experts in mobility issues</li>
                <li>Trained to address weakness or tightness in the body</li>
                <li>Experienced in delivering advanced programming to suit all goals.</li>
              </ul>
            </section>

            <section className={styles.modalSection}>
              <h3 className={`bold ${styles.modalSectionTitle}`}>Wellbeing Trainers</h3>
              <p>Ideal for beginners and getting started with your journey.</p>
              <p>Wellbeing trainers at Nuffield Health are:</p>
              <ul>
                <li>Qualified to train you to high industry standards</li>
                <li>Skilled in planning bespoke programmes</li>
                <li>Experienced in delivering fun, engaging sessions</li>
                <li>Able to educate you in health and wellbeing.</li>
              </ul>
            </section>
          </div>
          

          <div className={styles.modalControlsSection}>
            <Button
              onClick={toggleDifferentTypesPTModalOpen}
              isInline
              styleType='text'
            >
              Go back
            </Button>
          </div>

        </div>
      </Modal>
      <Modal
        isActive={isLocationModalOpen}
        isFullScreen
        closeFunc={toggleLocationModal}
        className={styles.locationModal}
        fullScreenWrapperClass={styles.locationFinderModalWrapper}
      >
        <LocationFinder />
      </Modal>
    </>
  )
}

export default SelectAPersonalTrainer
