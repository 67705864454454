import * as React from "react";

const SvgFilter = (props) => (
  <svg
    id="filter_svg__Icons"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    width="1em"
    height="1em"
    {...props}
  >
    <defs>
      <style>{".filter_svg__cls-1{fill:#00a200}"}</style>
    </defs>
    <path
      className="filter_svg__cls-1"
      d="M15.25 7.46h1.5v10.66h-1.5zM15.25 22.2h1.5v2.37h-1.5z"
    />
    <path
      className="filter_svg__cls-1"
      transform="rotate(-45 16.001 20.162)"
      d="M15.24 19.41h1.52v1.5h-1.52z"
    />
    <path
      className="filter_svg__cls-1"
      d="M11.43 11.72V7.46h-1.5v4.27a2.563 2.563 0 0 0 0 4.9v7.94h1.5v-7.94a2.563 2.563 0 0 0 0-4.9Zm-.75 3.51c-.59 0-1.06-.48-1.06-1.06s.48-1.06 1.06-1.06 1.06.48 1.06 1.06-.48 1.06-1.06 1.06ZM22.07 14.59V7.43h-1.5v7.15a2.563 2.563 0 0 0 0 4.9v5.06h1.5v-5.06a2.563 2.563 0 0 0 0-4.9Zm-.75 3.51c-.59 0-1.06-.48-1.06-1.06s.48-1.06 1.06-1.06 1.06.48 1.06 1.06-.48 1.06-1.06 1.06Z"
    />
  </svg>
);

export default SvgFilter;
