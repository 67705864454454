const routeUrls = {
  CART: 'cart',
  PACKAGES: 'packages',
  PERSONAL_TRAINER_REVIEW: 'your-personal-trainer',
  SELECT_PAYMENT_METHOD: 'select-payment-method',
  PAY_BY_CARD: 'pay',
  ORDER_CONFIRMATION: 'order-confirmation',
  INVALID_USER: 'invalid-user',
  SELECT_PERSONAL_TRAINER: 'select-personal-trainer',
  LOCATION_FINDER: 'location-finder',
}

export default routeUrls