import * as React from "react";

const SvgCloseSquared = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      clipPath="url(#Close_squared_svg__a)"
      stroke="#00A200"
      strokeWidth={3}
      strokeMiterlimit={10}
    >
      <path d="M1.533 22.456 22.456 1.533M1.533 1.533l20.923 20.923" />
    </g>
    <defs>
      <clipPath id="Close_squared_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgCloseSquared;
