import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Header from '../../components/Header'
import Body from '../../components/Body'
import BackButton from '../../components/Header/BackButton'
import useBreakpoints from '../../hooks/useBreakpoints'
import LocationFinder from '../../components/LocationFinder'
import { appContext } from '../../context'
import URL from '../../constants/route-urls'
import useDataLayer from '../../hooks/useDataLayer'

const LocationFinderPage = () => {
  const context = useContext(appContext)
  const navigate = useNavigate()
  const { dataLoadedEvent } = useDataLayer()

  const {
    screenFromLargeTablet,
  } = useBreakpoints()

  useEffect(() => {
    if (!context?.gymId) {
      navigate(`/${URL.SELECT_PERSONAL_TRAINER}`)
    }
  }, [])


  useEffect(() => {
    if (context?.basket) {
      dataLoadedEvent({
        pageType: 'Location Finder',
        pageName: 'Location Finder',
        pageUrl: `${window.location.origin}/${URL.LOCATION_FINDER}`,
        pageTitle: 'Change location',
      })
    }
  }, [context?.basket])

  return (
    <>
      <Helmet>
        <title>Change location</title>
      </Helmet>
      <Header
        title="Change location"
        hasBackButton={false}
        customBackButton={
          <BackButton
            customBackLink={`/${URL.SELECT_PERSONAL_TRAINER}/${context?.gymId ?? ''}`}
            buttonText={screenFromLargeTablet ? 'Back to Select A Personal Trainer' : 'Select Personal Trainer'}
            isHref={false}
          />
        }
      />
      <Body>
        <LocationFinder />
      </Body>
    </>
  )
}

export default LocationFinderPage