import usePlacesAutocomplete, {
  getGeocode,
} from 'use-places-autocomplete'
import TextInput from '../TextInput'
import styles from './GoogleAutocomplete.module.scss'

interface IComponentProps {
  getResponseData?(results: google.maps.GeocoderResult): void;
}

const GoogleAutocomplete = ({ getResponseData }: IComponentProps) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: 'gb' },
      types: ['geocode'],
    },
    debounce: 300,
  })

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Update the keyword of the input element
    setValue(e.target.value)
  }

  const handleSelect = ({ description }: {description: string}) => {
    return () => {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setValue(description, false)
      clearSuggestions()

      // Get latitude and longitude via utility functions
      getGeocode({ address: description }).then((results) => {
        if (getResponseData) {
          getResponseData(results[0])
        }
      })
    }
  }    

  const renderSuggestions = () => {
    return data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion

      return (
        <li
          key={place_id}
          onClick={handleSelect(suggestion)}
          className={styles.placeItem}
        >
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      )
    })
  }

  return (
    <>
      <div className={styles.autoComplete}>      
        <TextInput
          id='location-search'
          name='location-search'
          value={value}
          onChange={handleInput}
          disabled={!ready}
          autoComplete='off'
        />
        {/* We can use the "status" to decide whether we should display the dropdown or not */}
        {status === 'OK' && 
          <div className={styles.results}>
            <ul className={styles.placeList}>{renderSuggestions()}</ul>
          </div>
        }
      </div>
    </>
    
  )

}

export default GoogleAutocomplete