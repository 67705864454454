import * as React from "react";

const SvgChevronUp = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m115.125 91.125-5.25 5.25-31.5-31.5 5.25-5.25 31.5 31.5Zm-80.25 0 5.25 5.25 31.5-31.5-5.25-5.25-31.5 31.5ZM75 51l-5.25 5.25L75 61.5l5.25-5.25L75 51Z"
      fill="#00A200"
    />
  </svg>
);

export default SvgChevronUp;
