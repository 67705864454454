import * as React from "react";

const defaultColour = '#CCD2E3'

const SvgInfo = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"    
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    fill="none"
  >
    <circle cx={12} cy={12} r={9} stroke={props.fill || defaultColour} strokeWidth={2} />
    <path
      d="M12.5 7.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z"
      fill={props.fill || defaultColour}
      stroke={props.fill || defaultColour}
    />
    <path d="M12 17v-7" stroke={props.fill || defaultColour} strokeWidth={2} />
  </svg>
);

export default SvgInfo;
