import * as React from "react";

const SvgSearch = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M93.75 11.25c-24.75 0-45 20.25-45 45 0 9.75 3 18.75 8.25 25.875l-4.125 4.125 2.625 2.625L27.375 117l5.25 5.25L60.75 94.125l2.625 2.625 4.125-4.125c7.5 5.25 16.5 8.25 26.25 8.25 24.75 0 45-20.25 45-45 0-24.375-20.25-44.625-45-44.625Zm0 82.5c-20.625 0-37.5-16.875-37.5-37.5s16.875-37.5 37.5-37.5 37.5 16.875 37.5 37.5-16.875 37.5-37.5 37.5Zm-69.75 27 5.25 5.25-5.25 5.25-5.25-5.25 5.25-5.25Z"
      fill={props.fill || '#00A200'}
    />
  </svg>
);

export default SvgSearch;
