import { ReactNode } from 'react'
import styles from './PersonalTrainerCard.module.scss'
import Button from '../Button'
import { ChevronDown, ChevronUp, Cross, Tick } from '../icons'
import IconBulletPoint from '../IconBulletPoint/IconBulletPoint'
import trainerTypes, { TypeTrainerTypesKeys } from '../../constants/trainer-types'

interface IComponentProps {
  id: string;
  ptData: IPersonalTrainerData | undefined;
  isBioOpen?: boolean;
  isCancel?: boolean;
  customButton?: ReactNode;
  handleBioButtonClick(): void;
}

const YourPersonalTrainerContent = ({
  id,
  ptData,
  isBioOpen = false,
  customButton,
  handleBioButtonClick,
} : IComponentProps) => {
  return (
    <div className={`${styles.root} ${isBioOpen ? styles.cardOpen : ''}`} id={id}>
      <div className={styles.box}>
        <div className={styles.profile}>
          <div className={styles.profileRow}>
            <div className={styles.profileImg}>
              {!ptData?.profilePicture && <span>No Image</span>}
              {ptData?.profilePicture &&
                <img src={ptData?.profilePicture} alt='Personal Trainer' />
              }
            </div>
            <div className={styles.profileHeadings}>
              <div className={styles.profileTitles}>
                <h2 className={styles.title}>{ptData?.firstName} {ptData?.lastName}</h2>
                <h5 className={styles.role} data-testid='ptcard-role'>{trainerTypes[ptData?.level as TypeTrainerTypesKeys] ?? 'Personal Trainer'}</h5>
              </div>
              
              <div>
                {isBioOpen ?
                  <Button
                    className={styles.bioBtn}
                    onClick={handleBioButtonClick}
                  >
                    <span>Close bio</span>
                    <Cross fill="#3E7D34" className={styles.icon} width={20} height={20} />
                  </Button>
                  :
                  <Button
                    className={styles.bioBtn}
                    onClick={handleBioButtonClick}
                    testId='ptcard-open-bio-desktop'
                  >
                    <span>Read bio</span>
                    <ChevronDown fill="#3E7D34" stroke="#3E7D34" className={styles.icon} width={24} height={24} />
                  </Button>
                }
              </div>
            </div>
          </div>
          <div className={styles.specialities} data-testid='ptlister-specialities'>
            {ptData?.specialities &&
              <IconBulletPoint list={ptData?.specialities}>
                <Tick className={styles.tickBulletPoint} height={30} width={30} />
              </IconBulletPoint>
            }
          </div>

          {customButton &&
            <div className={styles.ctaBtn}>
              {customButton}
            </div>
          }

          <div className={styles.mobileBioTrigger}>
            <Button
              styleType='text'
              className={styles.mobileBioTriggerBtn}
              onClick={handleBioButtonClick}
              testId='ptcard-open-bio-mobile'
            >
              {isBioOpen ?
                <>
                  <span>Close bio</span>
                  <ChevronUp width={28} height={28} className={styles.mobileBioTriggerIcon} />
                </>
                :
                <>
                  <span>Read bio</span>
                  <ChevronDown width={28} height={28} className={styles.mobileBioTriggerIcon} />
                </>
              }
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default YourPersonalTrainerContent