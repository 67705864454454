
import cartContentStyles from './CartContent.module.scss'
import cartSummaryStyles from './CartSummary.module.scss'

const EmptyCart = () => (
  <div className={cartSummaryStyles.box}>
    <h2 className={cartSummaryStyles.boxHeading}>Cart</h2>
    <div className={cartContentStyles.summaryWrapper}>
      <div className={cartContentStyles.itemWrapper}>
        <div className={cartContentStyles.item}>
          <div className={cartContentStyles.itemTop}>
            <div className={cartContentStyles.titleWrapper}>
              <span>---</span>
            </div>
            <div>
              £0
            </div>
          </div>
        </div>
        <div className={cartContentStyles.item}>
          <div className={cartContentStyles.itemTop}>
            <div className={cartContentStyles.titleWrapper}>
              <span>---</span>
            </div>
            <div>
              £0
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default EmptyCart