import * as React from "react";
const SvgSuccessBadge = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.818 22.823H32l-5.962-5.934-1.374 1.382 2.615 2.603h-6.41v6.42l-4.873-4.874-4.873 4.874v-6.42H4.705L9.578 16l-4.873-4.874h6.418v-6.42l4.873 4.874 4.873-4.874v6.42h6.418l-2.625 2.624 1.38 1.38 5.95-5.953h-9.174V0l-6.822 6.823L9.174 0v9.177H0L6.822 16 0 22.823h9.174V32l6.822-6.823L22.818 32v-9.177Zm.963-5.435-1.378-1.378 1.378-1.375 1.378 1.375-1.378 1.378Z"
      fill="#2EA836"
    />
  </svg>
);
export default SvgSuccessBadge;
