import cartSummaryStyles from './CartSummary.module.scss'
import styles from './CartContent.module.scss'
import { CircleMinus, CirclePlus, Info } from '../icons'
import { useContext } from 'react'
import RemoveCart from './RemoveCart'
import { appContext } from '../../context'
import useRemoveItem from '../../hooks/useRemoveItem'
import Loader from '../Loader'
import URL from '../../constants/route-urls'
import capitalizeString from '../../utils/capitalize'
import trainerTypes, { TypeTrainerTypesKeys } from '../../constants/trainer-types'
import CardEditLink from '../CardEditLink/CardEditLink'

interface IComponentProps {
  id?: string | null;
  showRemove?: boolean;
  location?: string | null;
  locationId?: string | null;
  personalTrainer?: string | null;
  personalTrainerLevel?: string | null;
  packageName?: string | null;
  packageSeniority?: string | null;
  totalPrice?: number | null;
  discountedPrice?: number | null;
  isDiscounted?: boolean | null;
  quantity?: number;
  editableLocation?: boolean;
  editablePT?: boolean;
  editablePackage?: boolean;
  contractInfoLink?: boolean;
  firstSessionInfoLink?: boolean;
  totalLabelText?: string | null;
  displayQty?: boolean;
  isQtyEditable?: boolean;
}

const CartContent = ({
  id,
  showRemove = false,
  location,
  locationId,
  personalTrainer,
  personalTrainerLevel,
  packageName,
  packageSeniority,
  totalPrice,
  discountedPrice,
  quantity = 1,
  editableLocation = true,
  editablePT = true,
  editablePackage = true,
  displayQty = false, // Block qty completely
  isQtyEditable = false,
}: IComponentProps) => {
  const context = useContext(appContext)

  const {
    loading,
    addRemoveItemFromBasketHandler,
  } = useRemoveItem()

  const plusMinusQtyHandler = (type: string) => {
    if (type === 'add' && !loading) {
      addRemoveItemFromBasketHandler(id, quantity + 1)
      return
    }
    if (quantity > 1 && !loading) {
      addRemoveItemFromBasketHandler(id, quantity - 1)
    }
  }

  // Get current item seniority or hard code Normal for edit link.
  const seniority = packageSeniority ? capitalizeString(packageSeniority) : 'Normal'

  const personalTrainerLevelLabel = trainerTypes[personalTrainerLevel as TypeTrainerTypesKeys]
  const getCostPerSession = (price: number, sessions: number) => price / sessions

  let costPerSession
  if (typeof totalPrice === 'number') {
    switch (packageName) {
    case '4 hours':
      costPerSession = getCostPerSession(discountedPrice ?? totalPrice, 4)
      break
    case '8 hours':
      costPerSession = getCostPerSession(discountedPrice ?? totalPrice, 8)
      break
    default:
      costPerSession = getCostPerSession(discountedPrice ?? totalPrice, 12)
    }
  }

  return (
    <div>
      <h2 className={cartSummaryStyles.boxHeading}>Personal training details</h2>
      <p>We aim to contact you within 24 hours of payment confirmation to schedule your first session and confirm your booking.</p>
      <div className={styles.summaryWrapper}>
        <div className={cartSummaryStyles.sectionWrapper}>
          <div className={styles.sectionTitle}>
            <h3>Personal trainer</h3>
            <div className={styles.editWrapper}>
              {editablePT &&
                <CardEditLink
                  editTestId='edit-pt-button'
                  editLink={`/${URL.SELECT_PERSONAL_TRAINER}/${locationId}`}
                />
              }
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemTop}>
              <div className={styles.titleWrapper}>
                <h5 className={styles.title}>Trainer type</h5>
              </div>
            </div>
            <p className={styles.description}>{personalTrainerLevelLabel}</p>
          </div>
          <div className={styles.item}>
            <div className={styles.itemTop}>
              <div className={styles.titleWrapper}>
                <h5 className={styles.title}>Personal trainer</h5>
              </div>
            </div>
            <p className={styles.description}>{personalTrainer} (requested)</p>
          </div>
        </div>
        <div className={cartSummaryStyles.sectionWrapper}>
          <div className={styles.sectionTitle}>
            <h3>Package</h3>
            <div className={styles.editWrapper}>
              {editablePackage &&
                <CardEditLink
                  editTestId='edit-package-button'
                  editLink={`/${URL.PACKAGES}/${locationId}/${seniority}`}
                />
              }
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemTop}>
              <div className={styles.titleWrapper}>
                <h5 className={styles.title}>Package type</h5>
              </div>

            </div>
            <div className={styles.itemPackageWrapper}>
              <div className={styles.titleWrapper}>
                <p>{packageName}</p>
              </div>
              {displayQty &&
                <div className={styles.editWrapper}>
                  {isQtyEditable &&
                    <CircleMinus
                      height={28}
                      width={28}
                      className={(quantity > 1 && !loading) ? styles.plusMinusIcon : styles.iconDisabled}
                      onClick={() => plusMinusQtyHandler('remove')}
                      data-testid='test-minus-qty'
                    ></CircleMinus>
                  }

                  <div className={styles.info}>
                    {loading ?
                      <Loader isLoading={true} loaderSize={20} lineSize={4} hasBackground={false}></Loader> :
                      <p>{quantity}</p>
                    }
                  </div>

                  {isQtyEditable &&
                    <CirclePlus
                      height={28}
                      width={28}
                      className={!loading ? styles.plusMinusIcon : styles.iconDisabled}
                      onClick={() => plusMinusQtyHandler('add')}
                      data-testid='test-add-qty'
                    ></CirclePlus>
                  }
                </div>
              }
            </div>
          </div>
          {typeof costPerSession === 'number' &&
            <div className={styles.item}>
              <div className={styles.itemTop}>
                <div className={styles.titleWrapper}>
                  <h5 className={styles.title}>Price</h5>
                </div>
              </div>
              <p className={styles.description}>{context?.currencySymbol}{costPerSession.toFixed(2)} per hour</p>
            </div>
          }
          <div className={styles.info}><Info height={24} width={24} /><p>Personal training packages can be used as 30 or 60-minute sessions. Valid for 90 days after purchase.</p></div>
        </div>

        <div className={cartSummaryStyles.sectionWrapper}>
          <div className={styles.sectionTitle}>
            <h3>Location</h3>
            <div className={styles.editWrapper}>
              {editableLocation &&
                <CardEditLink
                  editTestId='edit-location-button'
                  editLink={`/${URL.LOCATION_FINDER}`}
                />
              }
            </div>
          </div>
          <div className={styles.itemWrapper}>
            <div className={styles.item}>
              <div className={styles.itemTop}>
                <div className={styles.titleWrapper}>
                  <h5 className={styles.title}>Gym location</h5>
                </div>
              </div>
              <p className={styles.description}>{location}</p>
            </div>
          </div>
        </div>
      </div>

      {showRemove &&
        <div className={styles.removeButtonWrapper}>
          <RemoveCart itemId={id} />
        </div>
      }

    </div>
  )
}

export default CartContent