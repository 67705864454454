const geoLocationAPI = window.navigator.geolocation
const getPosition = (
  successCb: PositionCallback,
  errorCb?: PositionErrorCallback | null | undefined,
  options?: PositionOptions | undefined,
) => {
  if (geoLocationAPI) {
    return geoLocationAPI.getCurrentPosition(successCb, errorCb, options)
  }
  
  return null
}

export { getPosition }