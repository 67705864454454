import { useEffect, useState } from 'react'
import { Alert } from '../icons'
import Button from '../Button'
import Modal from '../Modal'
import useRemoveItem from '../../hooks/useRemoveItem'
import styles from './RemoveCart.module.scss'
import { FullPageLoader } from '../Loader'

interface IComponentProps {
  itemId?: string | null;
}

const RemoveCart = (props:IComponentProps) => {
  const [isRemoveModalActive, setIsRemoveModalActive] = useState(false)
  const toggleRemoveModal = () => {
    setIsRemoveModalActive(!isRemoveModalActive)
  }
  const {
    response: basketData,
    loading,
    error,
    removeItemFromBasket,
  } = useRemoveItem()

  const removeProduct = () => {
    // Make POST from here to BE remove cart item service
    removeItemFromBasket(props.itemId)
  }

  useEffect(() => {
    if(basketData?.status === 200) {
      // close remove modal on successful removal
      setIsRemoveModalActive(false)
    }
  }, [basketData])

  return (
    <>
      <div className={styles.removeBtn}>
        <Button
          styleType='text'
          onClick={toggleRemoveModal}
          testId='remove-button'
        >
          Remove
        </Button>
      </div>

      <Modal
        isActive={isRemoveModalActive}
        closeFunc={toggleRemoveModal}
        title={
          <span className={styles.removeModalTitle}>
            <Alert width={36} height={36} />Remove item from cart?
          </span>
        }
        hasCornerCloseButton={false}
      >
        <div data-testid="remove-modal-content">
          <p>This will undo any products you have added and you may have to restart your journey</p>
          <>
            {error &&
              <div className={styles.errorWrapper}>An error occurred when removing this item from the cart</div>
            }
          </>
          <div className={styles.removeModalButtons}>
            <div>
              <Button
                styleType='secondary'
                onClick={toggleRemoveModal}
              >
                No, go back
              </Button>
            </div>
            <div>
              <Button
                styleType='danger'
                onClick={removeProduct}
              >
                Confirm
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      <FullPageLoader isLoading={loading} />
    </>
  )
}

export default RemoveCart