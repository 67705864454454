import { ReactNode } from 'react'
import styles from './PaymentMethodTrigger.module.scss'

interface IComponentProps {
  onClick(): void;
  methodName: string;
  icon?: ReactNode;
  isActive?: boolean;
}

const PaymentMethodTrigger = ({
  onClick,
  methodName,
  icon,
  isActive = false,
} : IComponentProps) => {
  return (
    <button
      className={styles.button}
      onClick={onClick}
    >
      <div className={`${styles.radio} ${isActive ? styles.radioActive : ''}`}></div>
      <div className={styles.nameWrapper}>
        {icon && <div className={styles.iconWrapper}>{icon}</div>}
        {methodName}
      </div>
    </button>
  )
}

export default PaymentMethodTrigger