import { Info, SuccessBadge, Warning } from '../icons'
import styles from './NotificationCard.module.scss'
import { IComponentProps } from './types'

const NotificationCard = ({
  type = 'danger',
  showIcon = true,
  isFullWidth,
  heading,
  children,
}: IComponentProps) => {
  let icon

  switch (type) {
  case 'info':
    icon = <Info className={styles.icon} width={24} height={24} fill='#6a7d80' data-testid='notification-card-icon-info' />
    break
  case 'warning':
    icon = <Warning className={styles.icon} width={24} height={24} fill='#FFA257' data-testid='notification-card-icon-warning' />
    break
  case 'success':
    icon = <SuccessBadge className={styles.icon} width={24} height={24} fill='#6a7d80' data-testid='notification-card-icon-info' />
    break
  default:
    icon = <Warning className={styles.icon} width={24} height={24} fill='#E50707' data-testid='notification-card-icon-danger' />
  }

  const headingMarginClass = !children ? 'noMargin' : ''
  const fullWidthClass = isFullWidth ? styles.fullWidth : ''

  return (
    <div className={`${styles.notificationCard} ${styles[type]} ${fullWidthClass}`} data-testid='notification-card'>
      {heading &&
        <h5 className={`${styles.heading} ${headingMarginClass}`} data-testid='notification-card-heading'>
          {showIcon && <div className={styles.iconWrapper} data-testid='notification-card-icon'>{icon}</div>}
          <div className={styles.headingText}>{heading}</div>
        </h5>
      }
      {children}
    </div>
  )
}

export default NotificationCard