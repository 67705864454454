import { ReactNode } from 'react'
import styles from './Badge.module.scss'

interface IComponentProps {
  bgColor?: string;
  color?: string;
  strikethrough?: boolean;
  children: ReactNode;
}

const Badge = ({
  bgColor = '#E2E2E2',
  color = 'inherit',
  strikethrough = false,
  children,
}: IComponentProps) => {
  const style = {
    backgroundColor: bgColor,
    textDecoration: strikethrough ? 'line-through' : 'none',
    color: color,
  }

  return (
    <div className={styles.root} style={style}>{children}</div>
  )
}

export default Badge