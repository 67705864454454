import { ReactNode } from 'react'
import styles from './Testimonial.module.scss'
import { Quote } from '../icons'

declare type ITestimonial = {
  title: string,
  children: ReactNode
}

/* Component for rendering testimonial quote with custom title and quote icon (awaiting image) */
const Testimonial = ({ title, children }: ITestimonial) => {

  return (
    <div className={styles.testimonial}>
      <div className={styles.titleRow}>
        <Quote className={styles.icon} width={37} height={37} data-testid='testimonial-quote-icon' />
        <h4 className='noMargin'>{title}</h4>
      </div>
      {children}
    </div>
  )
}

export default Testimonial