import { useContext, useEffect } from 'react'
import { appContext } from '../context'
import { getOid, getUserToken, isUserValid } from '../utils/getUserData'
import useFetch from './useFetch'

const userToken = getUserToken()
const customerId = getOid()

const useGetBasket = () => {
  const context = useContext(appContext)
  const {
    response: basketData,
    loading,
    error,
    fetchData,
  } = useFetch({
    method: 'GET',
    url: `/basket/basket/${customerId}`,
    headers: {
      'Authorization': `Bearer ${userToken}`,      
    },
  })

  const getBasket = async () => {
    // Check cookie to make sure user is valid before calling for cart
    if (isUserValid()) {
      return await fetchData()
    }
  }

  const getBasketById = async (basketId: string) => {
    // get basket by ID
    if (isUserValid()) {
      return await fetchData(
        {
          url: `/basket/basket/${customerId}/${basketId}`,
        },
      )
    }
  }

  useEffect(() => {
    if (error) {
      const errResponse = error as GenericObject
      context?.setBasketError(errResponse?.response?.data)
      return
    } else {
      context?.setBasketError(undefined)
    }

    if (basketData?.data && JSON.stringify(basketData?.data) !== JSON.stringify(context?.basket)) {
      const responseData: BasketType = basketData?.data
      context?.setBasket(responseData)
      context?.setTotalPrice(responseData?.price?.discountedPrice || responseData?.price?.totalPrice || 0)
    }
  }, [basketData, error])

  return {
    basketData,
    error,
    loading,
    getBasket,
    getBasketById,
  }
}

export default useGetBasket
