const getDistanceBetweenPoints = (point_1: coordsType, point_2: coordsType) => {

  const EARTH_RADIUS_IN_MILES = 3963.1

  const toRad = (num: number) => {
    return num * Math.PI / 180
  }

  // Haversine algorithm from https://github.com/toddself/haversort/blob/master/index.js
  let degreesLat = Math.abs(toRad(point_2.lat - point_1.lat))
  let degreesLon = Math.abs(toRad(point_2.lng - point_1.lng))
  let sinLat = Math.pow(Math.sin(degreesLat / 2), 2)
  let sinLon = Math.pow(Math.sin(degreesLon / 2), 2)
  let cosLat = Math.cos(toRad(point_1.lat)) * Math.cos(toRad(point_2.lat))
  let a = sinLat + (cosLat * sinLon)
  let c = 2 * Math.asin(Math.sqrt(a))
  let d = EARTH_RADIUS_IN_MILES * c

  return Math.round(d * 10) / 10
}

export default getDistanceBetweenPoints