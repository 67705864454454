import { useContext, useEffect } from 'react'
import { appContext } from '../../context'
import useGetBasket from '../../hooks/useGetBasket'
import CartContent from './CartContent'
import Total from './Total'
import styles from './CartSummary.module.scss'
import Loader from '../Loader'
import { AcceptCheckbox } from '../Checkbox'
import { NewWindow } from '../icons'
import NotificationCard from '../NotificationCard'

interface IComponentProps {
  showRemove?: boolean;
  showDiscountCodeForm?: boolean;
  callBasketOnRender?: boolean;
  testId?: string;
  editableLocation?: boolean;
  editablePT?: boolean;
  editablePackage?: boolean;
  contractInfoLink?: boolean;
  firstSessionInfoLink?: boolean;
  totalLabelText?: string | null;
  isQtyEditable?: boolean;
  showTCbox?: boolean;
  tcCheckboxCallback?(checkboxValue: boolean): void;
}

const CartSummary = ({
  showRemove = false,
  showDiscountCodeForm = true,
  callBasketOnRender = true,
  testId = 'cart-summary-component',
  editableLocation = false,
  editablePT = false,
  editablePackage = false,
  contractInfoLink = true,
  firstSessionInfoLink = true,
  totalLabelText = 'Total',
  isQtyEditable = false,
  showTCbox = false,
  tcCheckboxCallback,
}: IComponentProps) => {
  const { getBasket, loading, error } = useGetBasket()
  const context = useContext(appContext)

  useEffect(() => {
    if (callBasketOnRender) {
      getBasket()
    }
  }, [])

  const basketTotalPrice = context?.basket?.price?.totalPrice
  const basketDiscountedPrice = context?.basket?.price?.discountedPrice
  const isDiscounted = (basketDiscountedPrice || (context?.basket?.discountCodes && context?.basket?.discountCodes.length > 0)) ? true : false

  const tcBoxChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (tcCheckboxCallback) {
      tcCheckboxCallback(e.target.checked)
    }
  }

  return (
    <div className={styles.wrapper} data-testid={testId}>
      <Loader isLoading={loading} />
      {error as boolean &&
        <NotificationCard
          type='danger'
          heading="Sorry, we're experiencing some technical difficulties"
        >
          Please try again later or contact your local gym to book personal training.
        </NotificationCard>
      }

      {!error &&
        <>
          <div className={styles.cartItemsContainer}>
            {
              context?.basket?.commerceItems.map((item, index) => {
                return (
                  <CartContent
                    key={index}
                    id={item.commerceItemId}
                    showRemove={showRemove}
                    location={item.sessionPackage.location || 'Yet to be selected'}
                    locationId={item.sessionPackage.clubId}
                    personalTrainer={item.sessionPackage.personalTrainer || 'Yet to be selected'}
                    personalTrainerLevel={item.sessionPackage.level ? item.sessionPackage.level : 'Yet to be selected'}
                    packageSeniority={item.sessionPackage.level}
                    packageName={item.sessionPackage.size ? `${item.sessionPackage.size} hours` : 'Yet to be selected'}
                    totalPrice={item.price.totalPrice}
                    discountedPrice={item.price.discountedPrice}
                    isDiscounted={isDiscounted}
                    quantity={item.quantity || 1}
                    editableLocation={editableLocation}
                    editablePT={editablePT}
                    editablePackage={editablePackage}
                    contractInfoLink={contractInfoLink}
                    firstSessionInfoLink={firstSessionInfoLink}
                    totalLabelText={totalLabelText}
                    isQtyEditable={isQtyEditable}
                  />)
              })
            }
          </div>

          {typeof basketTotalPrice === 'number' &&
          <Total
            isDiscounted={isDiscounted}
            showDiscountCodeForm={showDiscountCodeForm}
            discountTitle={context?.basket?.discountCodes}
            discountedPrice={basketDiscountedPrice}
            totalPrice={basketTotalPrice}
            totalLabelText={totalLabelText}
          />
          }

          {showTCbox &&
          <div className={styles.checkboxContainer}>
            <AcceptCheckbox
              id='acceptTC'
              name='acceptTC'
              value='yes'
              onChange={tcBoxChangeHandler}
              testId='terms-checkbox'
            >
              <span>I agree to the <a href='https://www.nuffieldhealth.com/terms/nuffield-health-website-terms-and-conditions/personal-training-terms-and-conditions' target='_blank' rel="noreferrer">Terms of Service</a> and <a href='https://www.nuffieldhealth.com/pages/privacy' target='_blank' rel="noreferrer">privacy policy</a>.</span>
            </AcceptCheckbox>
            <div className={styles.newWindow}><p>Opens in a new window.<NewWindow height={24} width={24} /></p></div>
          </div>

          }
        </>
      }
    </div>
  )
}

export default CartSummary