import {
  ChangeEvent,
  CSSProperties,
  FocusEvent,
  HTMLInputTypeAttribute,
  KeyboardEvent,
  ReactNode,
  WheelEvent,
} from 'react'
import styles from './TextInput.module.scss'
interface IComponentProps {
  children?: ReactNode;
  id: string;
  label?: string | ReactNode;
  name: string;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onKeyUp?: (event: KeyboardEvent<HTMLInputElement>) => void;
  onWheel?: (event: WheelEvent<HTMLInputElement>) => void;
  placeholder?: string;
  style?: CSSProperties;
  disabled?: boolean;
  type?: HTMLInputTypeAttribute;
  value?: string;
  isError?: boolean;
  errorMsg?: string;
  testId?: string;
  autoComplete?: string;
  className?: string;
  errorMsgClassName?: string;
  maxlength?: number;
  min?: number;
  max?: number;
  pattern?: string;
}

const TextInput = ({
  id,
  label,
  name,
  onBlur,
  onChange,
  onFocus,
  onWheel,
  onKeyUp,
  placeholder,
  type,
  disabled = false,
  value,
  isError = false,
  errorMsg,
  testId,
  autoComplete,
  className,
  errorMsgClassName,
  maxlength,
  min,
  max,
  pattern,
}: IComponentProps) => {
  const fieldProperties = {
    id,
    name,
    onBlur,
    onFocus,
    onChange,
    onWheel,
    onKeyUp,
    type: 'text',
    value,
    placeholder,
    autoComplete,
    disabled,
    maxLength: maxlength,
    min,
    max,
    pattern,
  }

  const errorClass = (isError) ? `${styles.error}` : ''

  return (
    <div className={`${errorClass} ${className ? className : ''}`}>
      {label &&
        <label className={styles.label} htmlFor={id}>
          {label}
        </label>
      }
      
      <input
        className={styles.input}
        data-testid={testId}
        {...fieldProperties}
      />
      {isError && errorMsg &&
        <div
          className={errorMsgClassName ? errorMsgClassName : styles.errorMessage}
          data-testid='text-input-error'
        >
          {errorMsg}
        </div>
      }
    </div>
  )
}

export default TextInput