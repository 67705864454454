import { useContext, useEffect, useState } from 'react'
import { appContext } from '../../context'
import { LinkButton } from '../Button'
import { ArrowLeft } from '../icons'
import styles from './BackButton.module.scss'

interface IComponentProps {
  buttonText?: string;
  isHref?: boolean;
  customBackLink?: string;
}

const BackButton = ({
  buttonText = 'Back to gym',
  isHref = true,
  customBackLink,
}: IComponentProps) => {
  const context = useContext(appContext)
  const [backUrl, setBackUrl] = useState<string>(customBackLink ?? '')

  useEffect(() => {
    if (!customBackLink && context?.selectedGymData?.href) {
      // Get string after the last / in the href string.
      // e.g. /content/nuffieldhealth/en/gyms/services/personal-training/moorgate - moorgate is extracted
      const gymHref = /[^/]*$/.exec(context?.selectedGymData?.href) // returns an array
      
      if (gymHref?.length) {
        setBackUrl(`${process.env.REACT_APP_PRE_CHECKOUT_DOMAIN}/gyms/${gymHref[0]}/services/personal-training`)
      }
    }

    if (!customBackLink && !context?.selectedGymData?.href) {
      setBackUrl(`${process.env.REACT_APP_PRE_CHECKOUT_DOMAIN}/gyms/services/personal-training`)
    }
  }, [context?.selectedGymData])

  return (
    <LinkButton
      className={styles.backLink}
      isHref={isHref}
      link={backUrl ?? '/'}
      testId='header-back-to-gym-link'
    >
      <ArrowLeft width={35} height={35} className={styles.icon} />
      <span>{ buttonText }</span>
    </LinkButton>
  )
}

export default BackButton