import { useContext, useEffect } from 'react'
import { appContext } from '../context'
import { getOid, getUserToken } from '../utils/getUserData'
import useFetch from './useFetch'

const userToken = getUserToken()
const customerId = getOid()
// const customerId = '437aa7ad-85fc-443f-af3c-f1697911d903'

const useRemoveDiscountCode = (postData: GenericObject) => {
  const context = useContext(appContext)
  const {
    response,
    fetchData: removeDiscountCode,
  } = useFetch({
    method: 'DELETE',
    url: `/basket/basket/${customerId}/discount-code`,
    headers: {
      'Authorization': `Bearer ${userToken}`,
    },
    data: postData,
  })
  
  useEffect(() => {
    if(response?.data && JSON.stringify(response?.data) !== JSON.stringify(context?.basket)) {
      const responseData: BasketType = response?.data
      context?.setBasket(responseData)
      context?.setTotalPrice(responseData?.price?.discountedPrice ?? responseData?.price?.totalPrice ?? 0)

      if (responseData?.basketId) {
        context?.setBasketIdForPay(responseData?.basketId)
      }
    }
  }, [response])

  return {
    response,
    removeDiscountCode,
  }
}

export default useRemoveDiscountCode
