import { Helmet } from 'react-helmet'
import Body from '../../components/Body'
import Header from '../../components/Header'
import styles from './OrderConfirmation.module.scss'
import { useNavigate } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { appContext } from '../../context'
import { getHomeGym, getUserEmails } from '../../utils/getUserData'
import URL from '../../constants/route-urls'
import trainerTypes, { TypeTrainerTypesKeys } from '../../constants/trainer-types'
import useGetBasket from '../../hooks/useGetBasket'
import { FullPageLoader } from '../../components/Loader'
import ptIntroImage from '../../assets/images/pt-exercise.png'
import groupExerciseImage from '../../assets/images/group-exercise.jpg'
import physioImage from '../../assets/images/physiotherapy.jpg'
import { LinkButton } from '../../components/Button'
import useGetPlaces from '../../hooks/useGetPlaces'
import useDataLayer from '../../hooks/useDataLayer'

const OrderConfirmation = () => {
  const navigate = useNavigate()
  const context = useContext(appContext)
  const { getCartItemsForDataLayer, dataLoadedEvent } = useDataLayer()
  const [paymentType, setPaymentType] = useState<IOrderPaymentType | null>(null)
  const currentDate = () => {
    const d = new Date()
    const date = (`0${d.getDate()}`).slice(-2)
    const month = (`0${d.getMonth() + 1}`).slice(-2)
    const year = d.getFullYear()

    return `${date}/${month}/${year}`
  }
  const [isBasketLoading, setIsBasketLoading] = useState(true)

  const {
    getBasketById,
    basketData,
  } = useGetBasket()

  const {
    getPlacesData,
    placesData,
    loading: loadingLocations,
  } = useGetPlaces(getHomeGym()) // get specific location
  
  let gymLink = `${process.env.REACT_APP_PRE_CHECKOUT_DOMAIN}/gyms` // Fallback in case no gym set
  if (context?.selectedGymData?.href) {
    const gymHrefName = /[^/]*$/.exec(context?.selectedGymData?.href)

    if (gymHrefName?.length) {
      gymLink = `${process.env.REACT_APP_PRE_CHECKOUT_DOMAIN}/gyms/${gymHrefName[0]}/services/personal-training`
    }
  }

  useEffect(() => {
    // Clear payment method data
    context?.setPersistentPayMethod(null)
  }, [])

  useEffect(() => {
    const requestCart = () => {
      if (!context?.basket?.basketId) {
        console.error('No cart ID')
        navigate(`/${URL.SELECT_PERSONAL_TRAINER}`)
        return
      }

      getBasketById(context?.basket?.basketId).then((basketResponse) => {
        const responseBasketData: IBasketData = basketResponse?.data

        // If no payment ID has been found in the cart, redirect to step 1
        if (!responseBasketData?.paymentInfo?.paymentInfoId) {
          console.error('No payment ID')
          navigate(`/${URL.SELECT_PERSONAL_TRAINER}`)
          return
        }

        if (window.adyenPaymentType?.type) {   
          setPaymentType(window.adyenPaymentType)
          window.adyenPaymentType = null
        }
      
        setIsBasketLoading(false)

        // Fire data layer 
        dataLoadedEvent(
          {
            pageType: 'Order Confirmation',
            pageName: 'Order Confirmation',
            pageUrl: `${window.location.origin}/${URL.ORDER_CONFIRMATION}`,
            pageTitle: 'Confirmation',
          },
          {
            action: 'purchase',
            step: 1,
            type: 'member',
            id: responseBasketData?.paymentInfo?.paymentInfoId,
            revenue: responseBasketData?.price?.discountedPrice ?? responseBasketData?.price?.totalPrice,
            coupon: '',          
            couponType: '',
          },
          getCartItemsForDataLayer(),
        )
      })
    }

    // Request cart first time (retries are possible)
    requestCart()

    // Get places data for home gym 
    getPlacesData()
    
    return () => {
      // Cleanup: On unmount, the browser refresh causes all state to reset.
      window.location.reload()
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>Confirmation</title>
      </Helmet>

      <Header
        title="Confirmation of purchase"
        step={5}
        hasBackButton={false}
      />
      <Body className={styles.body}>
        <FullPageLoader isLoading={isBasketLoading || loadingLocations} setIsLoading={setIsBasketLoading} />
        {!isBasketLoading && basketData?.data && 
          <>
            <h2>Thank you for buying personal training</h2>
          
            <div className={styles.intro} data-testid='order-confirmation-intro'>
              <div className={styles.introCopy}>
                <p>A confirmation email is on its way to you. If you don't see it, please check your junk or spam folder and add us to your email safelist.</p>
              </div>
              <div className={styles.introImg}>            
                <img
                  src={ptIntroImage}
                  alt='Personal trainer session'
                />
              </div>
            </div>

            <div className={styles.cartSummarySection}>
              <h2>Order details</h2>
              <div className={styles.orderDetail}>
                <h4>Date of purchase</h4>
                <p data-testid='order-confirmation-date-of-purchase'>{currentDate()}</p>
              </div>
            
              <div className={styles.orderDetail}>
                <h4>Order reference</h4>
                <p data-testid='order-confirmation-payment-id'>{context?.basket?.paymentInfo.paymentInfoId}</p>
              </div>
            
              <div className={styles.orderDetail}>
                <h4>Email address</h4>
                <p data-testid='order-confirmation-email'>{ getUserEmails()[0] }</p>
              </div>
            
              <h2>Personal training details</h2>

              {context?.basket?.commerceItems[0].sessionPackage.level &&
              <div className={styles.orderDetail}>
                <h4>Trainer type</h4>
                <p data-testid='order-confirmation-level'>{trainerTypes[context?.basket?.commerceItems[0].sessionPackage.level as TypeTrainerTypesKeys]}</p>  
              </div>
              }
            
              <div className={styles.orderDetail}>
                <h4>Personal trainer</h4>
                <p data-testid='order-confirmation-pt-name'>{context?.basket?.commerceItems[0].sessionPackage.personalTrainer} (requested)</p>
              </div>
            
              <div className={styles.orderDetail}>
                <h4>Package</h4>
                <p data-testid='order-confirmation-size'>{context?.basket?.commerceItems[0].sessionPackage.size} hours</p>
              </div>

              <div className={styles.orderDetail}>
                <h4>Location</h4>
                <p data-testid='order-confirmation-location'>{context?.basket?.commerceItems[0].sessionPackage.location}</p>
              </div>

              <div className={styles.orderDetail}>
                <h4>Cost</h4>
                <p data-testid='order-confirmation-cost'>{context?.currencySymbol}{context?.basket?.price.discountedPrice?.toFixed(2) ?? context?.basket?.price.totalPrice?.toFixed(2)}</p>
              </div>

              {paymentType?.displayName &&
              <div className={styles.orderDetail}>
                <h4>Payment method</h4>
                <div className={styles.paymentType}  data-testid='order-confirmation-payment-type'>
                  {paymentType?.iconUrl &&
                    <img src={paymentType?.iconUrl} alt={paymentType?.displayName} className={styles.paymentBadge} />
                  }
                  {paymentType?.displayName}
                </div>
              </div>
              }
            </div>

            <div className={styles.contentSection}>
              <h2>What happens next?</h2>
              <p>A team member will be in touch soon to bring you up to speed with all you need to know about your personal training sessions. We'll let you know if your requested personal trainer is available. If not, we'll discuss your goals and assign a different Personal Trainer.</p>
              <p>Need help? Check out our <a href='https://www.nuffieldhealth.com/gyms/services/personal-training#pt-faqs'><strong>Personal training FAQs</strong></a>.</p>
              {(!loadingLocations && placesData) &&
              <div>
                <LinkButton
                  link={gymLink} 
                  isButtonStyle
                  isInline
                  isHref
                >
                  Return to gym
                </LinkButton>
              </div>
              }
            </div>

            <div className={styles.contentSection}>
              <h2>20% off Physiotherapy</h2>

              <div className={styles.intro}>
                <div className={styles.introCopy}>
                  <p>Whether you're recovering from an injury or want to maximize your training, our on-site physiotherapists can help prevent injuries and get you back up and running. All members get a <strong>20% discount</strong> on initial and follow-up physiotherapy appointments.</p>
                  <div>
                    <LinkButton
                      link='https://www.nuffieldhealth.com/physiotherapy'
                      isButtonStyle
                      isInline
                      isHref
                    >
                      Buy physiotherapy
                    </LinkButton>
                  </div>
                </div>
                <div className={styles.introImg}>
                  <img
                    src={physioImage}
                    alt='Physiotherapy'
                  />
                </div>
              </div>
            </div>

            <div className={styles.contentSection}>
              <h2>Group exercise classes</h2>

              <div className={styles.intro}>
                <div className={styles.introCopy}>
                  <p>Working out with others is a great way to stay motivated, meet like-minded people and improve your fitness. Unwind with a yoga class or work up a sweat in a cycle class, we offer a variety of classes designed for all ages and abilities.</p>
                  <div>
                    <LinkButton
                      link='https://www.nuffieldhealth.com/gyms/classes'
                      isButtonStyle
                      isInline
                      isHref
                    >
                      View classes
                    </LinkButton>
                  </div>
                </div>
                <div className={styles.introImg}>
                  <img
                    src={groupExerciseImage}
                    alt='Health Assessments'
                  />
                </div>
              </div>
            </div>
          </>
        }
      </Body>
    </>
  )
}

export default OrderConfirmation