import { useState, useContext } from 'react'
import GymCard from '../GymCards'
import styles from './LocationLister.module.scss'
import { useNavigate } from 'react-router-dom'
import { appContext } from '../../context'
import useDataLayer from '../../hooks/useDataLayer'

interface IComponentProps {
  places?: GymLocationDataListType;
}

const LocationLister = ({ places }: IComponentProps) => {
  const { configEvent } = useDataLayer()

  //Pagination constants 
  const paginationLimit = 9 // How many are shown in total after all revealed
  const paginationIncrement = 3 // How many are shown by default

  const context = useContext(appContext)
  const navigate = useNavigate()

  if (!places) {
    places = []
  }

  const maxNoOfLocationsToShow = places?.length > paginationLimit ? paginationLimit : places?.length
  const [noOfLocationsShown, setnoOfLocationsShown] = useState(paginationIncrement)
  const [isAllShown, setIsAllShown] = useState(false)

  const clickHandler = () => {
    setnoOfLocationsShown(maxNoOfLocationsToShow)
    setIsAllShown(true)
  }
  
  const displayedLocations = places?.slice(0, noOfLocationsShown) || []

  return (
    <>
      <div className={styles.gymCardList}>
        {
          // This is an example of the loop. It'd be best to have the location boxes as standalone components
          displayedLocations?.map((place: IGymLocationData, i: number) => {
            return (
              <div key={i} className={styles.gymCardWrapper}>
                <GymCard
                  gymData={place}
                  selectLocationFunc={() => {
                    if (place.salesForceId) {
                      navigate(`/select-personal-trainer/${place.salesForceId}`)
                      context?.setGymId(place.salesForceId)
                      
                      // Data layer config event
                      configEvent({
                        'action': 'select location',
                        'step': 1,
                      }, [{
                        'serviceLine': 'Personal Trainer',
                        'location': place.name ?? place.salesForceId,
                        'clubid': place.salesForceId,
                      }])
                    }
                  }}
                  key={i}
                />
              </div>
            )
          })
        }
      </div>
      
      {!isAllShown &&
        <div className={styles.viewMoreWrapper}>
          <button className={styles.viewMore} onClick={clickHandler}>
            <strong>Show the next {paginationLimit - paginationIncrement} closest gyms</strong>
          </button>
        </div>
      }
    </>
  )
}
export default LocationLister