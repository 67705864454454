import { useMediaQuery } from 'usehooks-ts'
import BREAKPOINTS from '../constants/breakpoints'

const useBreakpoints = () => {
  const screenMobileOnly = useMediaQuery(`(max-width: ${BREAKPOINTS.m - 1}px)`)
  const screenFromTablet = useMediaQuery(`(min-width: ${BREAKPOINTS.m}px)`)
  const screenFromLargeTablet = useMediaQuery(`(min-width: ${BREAKPOINTS.ml}px)`)
  const screenFromDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.l}px)`)
  const screenFromXlDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.xl}px)`)
  
  const screenUntilLargeTablet = useMediaQuery(`(max-width: ${BREAKPOINTS.ml - 1}px)`)
  const screenUntilDesktop = useMediaQuery(`(max-width: ${BREAKPOINTS.l - 1}px)`)
  const screenUntilXlDesktop = useMediaQuery(`(max-width: ${BREAKPOINTS.xl - 1}px)`)
  const screenTabletToLargeTablet = useMediaQuery(`(min-width: ${BREAKPOINTS.m}px) and (max-width: ${BREAKPOINTS.ml - 1}px)`)
  
  return {
    screenMobileOnly,
    screenFromTablet,
    screenFromLargeTablet,
    screenFromDesktop,
    screenFromXlDesktop,
    screenUntilLargeTablet,
    screenUntilDesktop,
    screenUntilXlDesktop,
    screenTabletToLargeTablet,
  }
}

export default useBreakpoints
