import Button from '../Button'
import styles from './GymCard.module.scss'

interface IComponentProps {
  gymData: GymLocationDataType;
  selectLocationFunc(): void; 
}

const GymCard = ({
  gymData,
  selectLocationFunc,
}: IComponentProps) => (
  <div className={styles.gymCard}>
    <span className={styles.distance}>{gymData.distance} miles</span>
    <div className={styles.details}>
      <h3 className={styles.gymName}>{gymData.name}</h3>
      <div className={styles.addressDetails}>
        {gymData.address1 ? ` ${gymData.address1}, ` : ' '}
        {gymData.address2 ? ` ${gymData.address2}, ` : ' '}
        {gymData.city ? ` ${gymData.city}, ` : ' '}
        {gymData.postCode ? ` ${gymData.postCode}` : ' '}<br />
        <strong className={styles.phoneNumber}>{gymData.phoneNumber}</strong>
      </div>
    </div>
    <div className={styles.buttonWrapper}>
      <Button onClick={selectLocationFunc}>View Personal Trainers</Button>
    </div>
  </div>
)

export default GymCard