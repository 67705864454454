import FilterPanel from './FilterPanel'
import useFilters from '../../hooks/useFilters'
interface IComponentProps {
  ptList: PersonalTrainerListDataType;
  unfilteredPtList: PersonalTrainerListDataType;
}

const Filters = ({ ptList, unfilteredPtList }: IComponentProps) => {
  const { createFilterArray } = useFilters()

  const levels = createFilterArray('level', ptList)
  const specialities = createFilterArray('specialities', ptList)
  
  return (
    <div>
      {levels &&
        <FilterPanel
          heading='Trainer Level'
          filterOptions={levels}
          ptAttributeBeingFiltered='level'
          unfilteredPtList={unfilteredPtList}
        />
      }
      {specialities &&
        <FilterPanel
          heading='Training Goal'
          filterOptions={specialities}
          ptAttributeBeingFiltered='specialities'
        />
      }
    </div>
  )
}

export default Filters