import { useContext } from 'react'
import { filterContext } from '../PersonalTrainerLister/filterContext'
import { IFiltersApplied } from './types'
import Checkbox from '../Checkbox'
import styles from './FilterOption.module.scss'

interface IComponentProps {
  label: string;
  option: string;
  count: number;
  changeFilters(option: string): void;
  ptAttributeBeingFiltered: keyof IFiltersApplied;
}

const FilterOption = ({
  label,
  option,
  count,
  changeFilters,
  ptAttributeBeingFiltered,
}: IComponentProps) => {
  const context = useContext(filterContext)
  const filterCategoryData = context?.appliedFilters[ptAttributeBeingFiltered as keyof IFiltersApplied]
  const isChecked = (filterCategoryData && filterCategoryData.indexOf(option) > -1) || false

  return (
    <div className={`${styles.filterOption} ${isChecked ? styles.checkedFilter : ''}`}>
      <Checkbox
        value={option}
        id={`filter-${option}`}
        name={`filter-${option}`}
        checked={isChecked}
        onChange={(e) => {
          changeFilters(option)
        }}
      >
        <div className={styles.optionText}>
          <div>{label}</div>
          <div>({count})</div>
        </div>
      </Checkbox>
    </div>
  )
}

export default FilterOption