import * as React from "react";

const SvgPaymentCard = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 40 26"
    {...props}
  >
    <path fill="#fff" d="M0 0h40v26H0z" />
    <path
      fill="#00112C"
      fillRule="evenodd"
      d="M30.1 21H9.9C8.84 21 8 20.17 8 19.15V6.85C8 5.83 8.85 5 9.9 5h20.2c1.05 0 1.9.83 1.9 1.85v12.3c0 1.02-.85 1.85-1.9 1.85z"
      clipRule="evenodd"
    />
    <rect width={5} height={2} x={25} y={17} fill="#fff" rx={0.5} />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M8 12h24V9H8v3z"
      clipRule="evenodd"
    />
  </svg>
);

export default SvgPaymentCard;
