import { ReactNode } from 'react'
import styles from './IconBulletPoint.module.scss'

interface IComponentProps {
  list: string[] | null;
  children: ReactNode;
}

/* Renders bullet point list with custom icon as a child */
const IconBulletPoint = ({ list, children }: IComponentProps) => (
  <div className={styles.customBulletPoints}>
    <ul>
      {list?.map(item => {
        return (
          <li className={styles.bulletPoint} key={item} data-testid='iconbulletpoint-item'>
            {children}
            <span className={styles.innerText}>{item}</span>
          </li>
        )
      })}
    </ul>
  </div>
)

export default IconBulletPoint