import decodedUserToken from './decodedUserToken'
import getUserCookie from './getUserCookie'

export const getEligibilityData = () => {
  try {
    return JSON.parse(decodedUserToken()?.Eligibility)
  } catch (e) {
    return {}
  }
}

export const getUserToken = () => {
  return getUserCookie().token
}

export const getUserEmails = () : string[] => {
  return decodedUserToken().emails ?? []
}

export const getOid = () => {
  return decodedUserToken().oid
}

export const getHomeGym = () => {
  return getEligibilityData()?.A_GYM_Site
}

export const isUserValid = () => {
  const eligibility = getEligibilityData()

  const devEnvOverride = (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') && process.env.REACT_APP_ALLOW_INVALID_USER_COOKIE === 'true'
  const validUserRule = eligibility?.A_GYM === 'A' && (eligibility?.A_GYM_Site && typeof eligibility?.A_GYM_Site === 'string' && eligibility?.A_GYM_Site !== '')

  if (devEnvOverride || validUserRule) {
    return true
  }

  return false
}