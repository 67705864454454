import { Helmet } from 'react-helmet'
import Body from '../components/Body'
import Header from '../components/Header'
import pageStyle from './PageContent.module.scss'
import { DataLoadedEvent } from '../components/DataLayer'
import { useLocation } from 'react-router-dom'

const NotFound = () => {
  const location = useLocation()
  const { pathname } = location

  return (
    <>
      {/* Head */}
      <Helmet>
        <title>Page Not Found</title>
      </Helmet>
      <DataLoadedEvent
        pageType='404'
        pageName='404'
        pageUrl={`${window.location.origin}/${pathname}`}
        pageTitle='Page Not Found'
      />
      <Header
        title="Nuffield Health"
        hasBackButton={false}
      />
      <Body>
        <section className={`${pageStyle.pageSection} ${pageStyle.introText}`}>
          <h1>Page not found</h1>
          <p>You may have entered the address incorrectly or followed an old link.</p>
          <p>Try again from <a href="http://www.nuffieldhealth.com/gyms/services/personal-training" title="Link: http://www.nuffieldhealth.com/gyms/services/personal-training">www.nuffieldhealth.com/personal-training</a>, or let us know about the problem by <a href="mailto:advicecentre@nuffieldhealth.com" title="Link: mailto:advicecentre@nuffieldhealth.com">emailing us</a>.</p>
        </section>
      </Body>
    </>
  )
}

export default NotFound