import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import Packages from './Packages'
import ReviewConfirm from './ReviewConfirm'
import NotFound from './404'
import Pay from './Pay'
import OrderConfirmation from './OrderConfirmation'
import InvalidUser from './InvalidUser'
import SelectAPersonalTrainer from './SelectAPersonalTrainer'
import { useEffect } from 'react'
import { isUserValid } from '../utils/getUserData'
import URL from '../constants/route-urls'
import LocationFinder from './LocationFinder'

const RouteComponents = () => {
  const navigate = useNavigate()

  useEffect(() => {
    if (!isUserValid()) {
      navigate(`/${URL.INVALID_USER}`)
    }
  }, [])

  return (
    <Routes>
      <Route path={`${URL.PACKAGES}/:urlGymId/:seniority`} element={<Packages />} />      
      <Route path={URL.CART} element={<ReviewConfirm />} />
      <Route path={URL.PAY_BY_CARD} element={<Pay />} />
      <Route path={URL.ORDER_CONFIRMATION} element={<OrderConfirmation />} />
      <Route path={URL.INVALID_USER} element={<InvalidUser />} />
      <Route path={URL.SELECT_PERSONAL_TRAINER}>
        <Route index element={<SelectAPersonalTrainer />} />
        <Route path=":urlGymId" element={<SelectAPersonalTrainer />} />
      </Route>
      <Route path={URL.LOCATION_FINDER} element={<LocationFinder />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

const ScrollToTopOnNavigation = () => {
  const { pathname } = useLocation()

  useEffect(() => {    
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto',
    })
  }, [pathname])

  return null
}

const AppRoutes = () => {
  return (
    <Router>
      <ScrollToTopOnNavigation />
      <RouteComponents />
    </Router>
  )
}

export { RouteComponents }
export default AppRoutes