import { ReactNode } from 'react'
import style from './Button.module.scss'

interface IComponentProps {
    onClick(event: React.MouseEvent<HTMLButtonElement>): void;
    styleType?: 'primary' | 'secondary' | 'danger' | 'text' | 'disabled';
    children: ReactNode;
    disabled?: boolean;
    testId?: string;
    isInline?: boolean;
    className?: string;
    isFullWidth?: boolean;
    type?: 'button' | 'submit' | 'reset';
    form?: string;
}

const Button = ({
  onClick,
  styleType = 'primary',
  children,
  disabled = false,
  testId,
  isInline,
  className,
  isFullWidth,
  type,
  form,
}: IComponentProps) => {

  return (
    <button
      className={`
        ${className ? className : ''}
        ${style.root}
        ${style[styleType]}
        ${disabled ? style.disabled : ''}
        ${isInline ? style.inline : ''}
        ${isFullWidth ? style.fullWidth : ''}
      `}
      onClick={onClick}
      disabled={disabled}
      data-testid={testId}
      type={type}
      form={form}
    >
      {children}
    </button>
  )
}

export default Button