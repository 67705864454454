import * as React from "react";

const SvgSmartwatch = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 44 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M29.077 17.213 27.52 18.77l1.556 1.556 1.555-1.556-1.555-1.556Z"
      fill="#00A200"
    />
    <path
      d="M31.9 36.8H12.1c-1.32 0-3.3-.88-3.3-3.3v-22c0-1.32.88-3.3 3.3-3.3h19.8c1.32 0 3.3.88 3.3 3.3v22c0 1.32-.88 3.3-3.3 3.3ZM11 11.5v22c0 .88.55 1.1 1.1 1.1h19.8c.88 0 1.1-.55 1.1-1.1v-22c0-.88-.55-1.1-1.1-1.1H12.1c-.88 0-1.1.55-1.1 1.1ZM38.5 26.9h-3.3v-2.2h1.1v-4.4h-1.1v-2.2h3.3v8.8Z"
      fill="#00A200"
    />
    <path
      d="m20.13 33.61-3.63-4.84-1.65 2.53H12.1v-2.2h1.65l2.75-4.07 2.97 3.96L24.2 15.9 28.27 28h3.63v2.2h-5.17l-2.53-7.7-4.07 11.11ZM17.6 21.73l-.66-.55c-1.87-1.54-4.73-4.4-3.52-6.27.55-.88 1.21-1.1 1.76-1.21.99-.11 1.87.44 2.53.99.66-.55 1.54-.99 2.53-.99.55 0 1.21.33 1.76 1.21 1.1 1.87-1.76 4.73-3.52 6.27l-.88.55Zm-2.31-5.83c.11.55 1.1 1.76 2.31 2.86 1.21-1.1 2.2-2.42 2.31-2.86-.22 0-.99.44-1.54.99l-.77.88-.77-.88c-.55-.55-1.32-.99-1.54-.99ZM17.6 42.3h-2.2v-2.97l-1.98-2.97 1.76-1.32 2.42 3.63v3.63ZM28.6 42.3h-2.2v-3.63l2.42-3.63 1.76 1.32-1.98 2.97v2.97ZM15.18 9.96l-1.76-1.32 1.98-2.97V2.7h2.2v3.63l-2.42 3.63ZM28.82 9.96 26.4 6.33V2.7h2.2v2.97l1.98 2.97-1.76 1.32Z"
      fill="#00A200"
    />
  </svg>
);

export default SvgSmartwatch;
