import { useEffect, useContext } from 'react'
import { appContext } from '../context'
import { isUserValid } from '../utils/getUserData'
import useFetch from './useFetch'

const useGetPlaces = (locationId?: string) => {
  const context = useContext(appContext)
  const {
    response: placesData,
    loading,
    error,
    fetchData,
  } = useFetch({
    method: 'GET',
    url: `/places/locations/${locationId ? locationId : ''}`,
  })
  
  useEffect(() => {
    context?.setLocationApiFailure(null)

    if (error) {
      context?.setLocationApiFailure(error)
      context?.setSelectedGymData(null)
      return
    }

    // Logic to NOT store all locations when getting the entire list of gyms
    if (placesData?.data && !placesData?.data.locations) {
      context?.setSelectedGymData(placesData.data)
    }
  }, [placesData, error])

  const getPlacesData = () => {
    if (isUserValid()) {
      fetchData()
    }
  }

  return {
    placesData,
    error,
    loading,
    getPlacesData,
  }
}

export default useGetPlaces
