import { useEffect } from 'react'
import { loadingTimeout } from '../../constants/timers'
import { IComponentProps } from './types'
import style from './Loader.module.scss'

const Loader = ({
  isLoading = false,
  loaderSize = 120,
  lineSize = 16,
  text = '',
  hasBackground = true,
  setIsLoading,
}: IComponentProps) => {
  const sizeStyle = {
    width: `${loaderSize}px`,
    height: `${loaderSize}px`,
    borderWidth: `${lineSize}px`,
  }

  const bgStyle = hasBackground ? style.background : ''

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>

    if (isLoading && setIsLoading) {
      timeout = setTimeout(() => {
        setIsLoading(false)
      }, loadingTimeout)
    }

    return () => clearTimeout(timeout)
  }, [isLoading])

  // REQUIRES PARENT CONTAINER TO HAVE POSITION: RELATIVE
  return (
    <>
      {isLoading &&
        <div className={`${style.root} ${bgStyle}`}>
          <div
            className={style.loaderWrapper}
          >
            <div
              className={style.loader}
              style={sizeStyle}
            ></div>

            {text &&
              <p className={style.text}>{text}</p>
            }
          </div>
        </div>        
      }
    </>
  )
}

export default Loader