import { useState, useRef, useEffect } from 'react'
import useBreakpoints from '../../hooks/useBreakpoints'
import { Chevron2Down } from '../icons'
import style from './MobileDropDownPanel.module.scss'
import { IComponentProps } from './types'

const MobileDropDownPanel = ({
  heading,
  headingIcon,
  children,
  bodyBgColor = '#F5F5F5',
  bodyBorderTopColor = '#00A200',
} : IComponentProps) => {
  const bodyCopyContainer = useRef<HTMLDivElement>(null)
  const bodyWrapper = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)

  const {
    screenMobileOnly, // < 767px
    screenFromTablet, // >= 768px
  } = useBreakpoints()

  let bodyWrapperStyles:React.CSSProperties = {}

  if (screenMobileOnly) {
    bodyWrapperStyles = {
      backgroundColor: bodyBgColor,
      borderTop: '2px solid #fff',
    }

    if (isOpen) {
      // Set border when open
      bodyWrapperStyles.borderTop = `2px solid ${bodyBorderTopColor}`
    }
  }

  if (isOpen) {
    // Set height of wrapper to height of body copy within.
    // Using JS to get that body copy height for an even transition animation.
    bodyWrapperStyles.maxHeight = `${bodyCopyContainer.current?.offsetHeight}px`
  }

  const handleOpenClick = () => {
    if (screenFromTablet) {
      // Do nothing if desktop view
      return
    }

    // Open body panel
    setIsOpen(!isOpen)
  }

  // Fire when breakpoint hits 768px
  useEffect(() => {
    if (screenFromTablet) {
      // Open content if user goes from mobile to desktop view...
      setIsOpen(true)
    } else {
      // Close content if user goes from mobile to desktop view...
      setIsOpen(false) 
    }
  }, [screenFromTablet])
  
  return (
    <div>
      <button
        className={ style.headingSection }
        onClick={ handleOpenClick }
        data-testid='mobile-dropdown-heading'
      >
        <div className={style.headingIcon}>
          { headingIcon }
        </div>
        <div className={ style.headingElemWrapper }>
          { heading }
        </div>
        <div className={ style.headingChevWrapper }>
          <Chevron2Down
            width={ 35 }
            height={ 35 }
            className={`
              ${style.chevron}
              ${isOpen ? style.chevronRotate : ''}
            `}
            data-testid='mobile-dropdown-chevron-icon'
          />
        </div>
      </button>
      <div
        className={ style.bodyWrapper }
        style={ bodyWrapperStyles }
        data-testid='mobile-dropdown-body'
        ref={ bodyWrapper }
      >
        <div
          className={ style.bodyPanel }
          ref={ bodyCopyContainer }
          data-testid='mobile-dropdown-copy'
        >
          { children }
        </div>
      </div>
    </div>
  )
}

export default MobileDropDownPanel
