import { useContext } from 'react'
import { filterContext } from '../PersonalTrainerLister/filterContext'
import useFilters from '../../hooks/useFilters'
import styles from './FilterPills.module.scss'
import { Cross } from '../icons'
import { IFiltersApplied } from './types'
import trainerTypes, { TypeTrainerTypesKeys } from '../../constants/trainer-types'

const FilterPills = () => {
  const filterContextData = useContext(filterContext)
  const { removeFilter } = useFilters()
  
  const remove = (ptFilterAttr: keyof IFiltersApplied, optionToRemove: string) => {
    removeFilter(ptFilterAttr, optionToRemove)
  }

  return (
    <div className={styles.filterPills}>
      <ul className='noStyles'>
        {filterContextData?.appliedFilters?.level?.map(filter => {
          return (
            <li key={filter} className={styles.pillWrapper}>
              <button
                onClick={() => {
                  remove('level', filter)
                }}
                className={styles.pill}
              >
                <div>
                  {trainerTypes[filter as TypeTrainerTypesKeys]} 
                </div>
                <div className={styles.pillCloseIconWrapper}>
                  <Cross width={24} height={24} className={styles.pillCloseIcon} />
                </div>
              </button>
            </li>
          )
        })}

        {filterContextData?.appliedFilters?.specialities?.map(filter => (
          <li key={filter} className={styles.pillWrapper}>
            <button
              onClick={() => {
                remove('specialities', filter)
              }} 
              className={styles.pill}
            >
              <div>
                {filter} 
              </div>
              <div className={styles.pillCloseIconWrapper}>
                <Cross width={24} height={24} className={styles.pillCloseIcon} />
              </div>
            </button>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default FilterPills