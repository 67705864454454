import PersonalTrainerLister, { IComponentProps as IPTListerProps } from './PersonalTrainerLister'
import FilterContext from './filterContext'

const PTListerContextWrapper = ({
  ptList,
  customButton,
  gymId,
  isBioOpen = false,
}: IPTListerProps) => {
  return (
    <FilterContext>
      <PersonalTrainerLister 
        ptList={ptList}
        customButton={customButton}
        gymId={gymId}
        isBioOpen={isBioOpen}
      />
    </FilterContext>
  )
}

export default PTListerContextWrapper