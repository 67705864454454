import { ReactNode, useContext, useEffect, useState } from 'react'
import { appContext } from '../../context'
import Button from '../Button'
import { useNavigate } from 'react-router-dom'
import capitalizeString from '../../utils/capitalize'
import PersonalTrainerCard from './PersonalTrainerCard'
import styles from './PersonalTrainerLister.module.scss'
import IconBulletPoint from '../IconBulletPoint/IconBulletPoint'
import randomNum from '../../utils/generateRandomNum'
import {
  Clipboard,
  CloseSquared,
  Cross,
  Filter,
  Smartwatch,
  Tick,
} from '../icons'
import Testimonial from '../Testimonial/Testimonial'
import { IPersonalTrainerDataWithId } from './types'
import splitArrayIntoSubArrays from '../../utils/splitArrayIntoSubArrays'
import useBreakpoints from '../../hooks/useBreakpoints'
import Filters from '../PersonalTrainerFilters'
import { filterContext } from './filterContext'
import FilterPills from '../PersonalTrainerFilters/FilterPills'
import Modal from '../Modal'
import useFilters from '../../hooks/useFilters'
import useDataLayer from '../../hooks/useDataLayer'
import { useIsFirstRender } from '../../hooks/useIsFirstRender'

export interface IComponentProps {
  ptList?: PersonalTrainerListDataType;
  customButton?: ReactNode;
  gymId?: string;
  isBioOpen?: boolean;
}

const PersonalTrainerLister = ({
  ptList,
  customButton,
  gymId,
  isBioOpen = false,
}: IComponentProps) => {
  const context = useContext(appContext)
  const filtContext = useContext(filterContext)
  const { filtersEvent, ptBioEvent } = useDataLayer()
  const navigate = useNavigate()
  const [ptListWithId, setPtListWithId] = useState<IPersonalTrainerDataWithId[] | null>(null)
  const [openBioData, setOpenBioData] = useState<IPersonalTrainerDataWithId | null>(null)
  const [chunkedArray, setChunkedArray] = useState<any[] | null>()
  const [displayedPt, setDisplayedPt] = useState<PersonalTrainerListDataType>(ptList)
  const [isMobileFilterOpen, setIsMobileFilterOpen] = useState<boolean>(false)
  const {
    screenTabletToLargeTablet,
    screenFromLargeTablet,
  } = useBreakpoints()
  const { clearFilters } = useFilters()
  const totalFiltersApplied = filtContext?.appliedFilters ? (filtContext?.appliedFilters.specialities.length + filtContext?.appliedFilters.level.length) : 0

  // Returns PTs that match the filters. All by default (when no filters selected)
  const filterPersonalTrainers = () => {
    // Get current filters applied
    const filteredSpeciality = filtContext?.appliedFilters.specialities ?? []
    const filteredLevel = filtContext?.appliedFilters.level ?? []

    return ptList?.filter(pt => {
      const ptSpecialities = pt.specialities

      // Create an array of specialities matched between pt.specialities and filtered specialities
      const filteredSpecialitiesFoundInPt = ptSpecialities.filter(ptSpec => {
        // Loop filtered specialities and find each one in the PT's specialities array.
        const hasFilteredSpeciality = filteredSpeciality.some(filteredSpec => {
          // Convert both strings to lowercase to ensure that the strings match.
          return ptSpec.toLowerCase() === filteredSpec.toLowerCase()
        })
        return hasFilteredSpeciality
      })

      // Check if PT level exists filtered level array.
      // If no levels are selected, all levels are assumed to be searched for (value defaults to true)
      const levelMatch = filteredLevel.length > 0 ? filteredLevel.indexOf(pt.level.toLowerCase()) > -1 : true

      // Check if number of filtered specialities found in a PT match the number of specialities filtered
      // If no specialities are selected, all specialities are assumed to be searched for (value defaults to true)
      const specialitiesMatch = filteredSpeciality.length > 0 ? filteredSpeciality.length === filteredSpecialitiesFoundInPt.length : true
      
      return levelMatch && specialitiesMatch
    })
  }

  const isFirstRender = useIsFirstRender()

  useEffect(() => {
    // Update displayed PTs when the filters are updated
    if (filtContext?.appliedFilters) {
      setDisplayedPt(filterPersonalTrainers())
    }

    // Avoid filter event trigger on inital render
    if ((totalFiltersApplied > 0) || ((totalFiltersApplied < 1) && !isFirstRender)) {
      filtersEvent()
    }
  }, [filtContext?.appliedFilters.level.length, filtContext?.appliedFilters.specialities.length])

  useEffect(() => {
    // clear filters when location changes
    clearFilters()
  }, [context?.gymId])

  // Rebuild PT list every time displayedPt is updated
  useEffect(() => {
    // Add an ID to each PT item
    const listWithId = displayedPt?.map((ptData, i) => {
      const ptIdStr = `${ptData.firstName} ${ptData.lastName} ${ptData.role}`
      const ptId: string = ptIdStr

      return {
        id: ptId,
        ...ptData,
      }
    })

    if (listWithId?.length) {
      setPtListWithId(listWithId)

      if (isBioOpen) {
        setOpenBioData(listWithId[0])
      }
    }
  }, [displayedPt])

  // Use effect to rearrange grid based on pt data and screen width 
  useEffect(() => {
    let gridWidth = 1
    if (screenFromLargeTablet) {
      gridWidth = 2
    }
    
    setChunkedArray( splitArrayIntoSubArrays(ptListWithId?.slice(0, ptListWithId.length) ?? ['No data'], gridWidth) )
  }, [screenTabletToLargeTablet, screenFromLargeTablet, ptListWithId])

  const toggleMobileFilterModal = () => { setIsMobileFilterOpen(!isMobileFilterOpen) }

  return (
    <>
      <button className={styles.mobileFilterTriggerButton} onClick={toggleMobileFilterModal}>
        <div className={styles.mobileFilterTrigger} data-testid='ptlister-mobile-filter-trigger'>
          <div>Filter trainers</div>
          <div className={styles.mobileFilterTriggerIconWrapper}>
            <Filter
              width={38}
              height={38}
              className={styles.mobileFilterTriggerIcon}
            />
            {totalFiltersApplied > 0 && <div className={styles.mobileFilterCounter}>{totalFiltersApplied}</div>}
          </div>
          {totalFiltersApplied > 0 &&
            <div>
              <Button
                className={styles.mobileFilterBarClear}
                styleType='text'
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation()
                  clearFilters()
                }}
              >
                Clear filters
              </Button>
            </div>
          }
        </div>
      </button>
      
      <div
        className={styles.ptLister}
        data-testid='ptlister-main'
      >
        <div className={styles.ptFilterWrapper} data-testid='pt-filter-desktop'>
          <div className={styles.ptFilterInnerWrapper}>
            <div className={styles.ptListerHeadingWrapper}>
              <div>
                <h4 className={styles.ptFilterMainHeading}>Filter by</h4>
              </div>
              {totalFiltersApplied > 0 &&
                <div>
                  <Button styleType='text' onClick={clearFilters}>
                    Clear all
                  </Button>
                </div>
              }
            </div>
            <FilterPills />
            <Filters ptList={displayedPt} unfilteredPtList={ptList} />
          </div>
        </div>
        <div className={`
          ${styles.ptCardLister}
        `}>
          <h4 className={styles.ptListerHeading} data-testid='ptlister-heading'>Personal Trainers {displayedPt && <>({displayedPt.length})</>}</h4>
          {displayedPt?.length === 0 && 
            <div className={styles.noPtFound}>
              <h2>No results found</h2>
              <p>Please try adjusting your filters.</p>
            </div>
          }
          {
            displayedPt && displayedPt.length > 0 && chunkedArray?.map((ptRowData: IPersonalTrainerDataWithId[], i: number) => {
              const isBioOpenInThisRow = ptRowData.some((pt) => pt.id === openBioData?.id)
              return (
                <div className={`
                  ${styles.ptListRow}                
                  ${isBioOpenInThisRow ? styles.bioOpenInRow : ''}
                `} key={i}>
                  <div className={styles.rowCardWrapper}>
                    {
                      ptRowData?.map((ptData, i) => {
                        const strForId = `${ptData.firstName} ${ptData.lastName} ${ptData.role}`
                        const ptId = strForId

                        return (
                          <div key={ptId} className={styles.ptCardContainer} data-testid='ptlister-card-wrapper'>
                            <PersonalTrainerCard
                              id={ptId}
                              isBioOpen={openBioData?.id === ptId}
                              ptData={ptData}
                              handleBioButtonClick={() => {
                                if (openBioData?.id === ptId) {
                                  // If the card that's already open is clicked again, close it
                                  setOpenBioData(null)
                                  return
                                }
                                ptBioEvent(`${ptData.firstName} ${ptData.lastName}`)
                                setOpenBioData(ptData)
                              }}
                              customButton={
                                <>
                                  {customButton}
                                  {!customButton &&
                                  <Button onClick={() => {
                                    context?.setSelectedPersonalTrainer(ptData)
                                    navigate(`/packages/${gymId || ptData.location}/${capitalizeString(ptData.level)}`)
                                  }}>Request this trainer</Button>
                                  }
                                </>
                              }
                            />
                          </div>
                        )
                      })
                    }
                  </div>
                
                  {
                    ptRowData?.map((ptData, i) => {
                      const ptIdStr = `${ptData.firstName} ${ptData.lastName} ${ptData.role}`
                      const ptId = ptIdStr

                      if (openBioData?.id === ptId) {
                        return (
                          <div className={styles.collapsiblePanel} key={i}>
                            <div className={styles.collapsiblePanelInner}>
                              <div className={styles.closePanelButtonWrapper}>
                                <Button
                                  styleType='text'
                                  onClick={() => { setOpenBioData(null) }}
                                  testId='ptlister-bio-panel-close-btn'
                                >
                                  <span>Close bio</span> <Cross width={20} height={20} className={styles.closeBioIcon} />
                                </Button>
                              </div>

                              <div className={styles.profileDetails}>
                                <h4 className={`${styles.subheading} ${styles.topSubheading}`}>
                                  <Smartwatch className={styles.subheadingIcon} width={37} height={37} />
                                  How can I help you improve your health
                                </h4>
                                <p className={styles.biography}>{openBioData?.biography}</p>              
                              </div>
                              <div className={styles.panelBottomRow}>
                                <div
                                  className={`${styles.profileDetails} ${styles.qualifications}`}
                                  data-testid='ptlister-qualifications'
                                >
                                  <h4 className={`${styles.subheading} noMargin`}>
                                    <Clipboard className={styles.subheadingIcon} width={37} height={37} />
                                    My qualifications
                                  </h4>
                                  
                                  {openBioData?.qualifications &&
                                  <IconBulletPoint list={openBioData?.qualifications}>
                                    <Tick className={styles.tickBulletPoint} height={30} width={30} />
                                  </IconBulletPoint>
                                  }
                                </div>
                                <div className={styles.testimonial}>
                                  <Testimonial title="What my clients say">
                                    {openBioData?.testimonials?.map((item, i) => {
                                      const key = randomNum(10000, 99999)
                                      return <p key={key * i} className='noMargin'>{item}</p>
                                    })}
                                  </Testimonial>
                                </div>
                              </div>
                              
                            </div>
                          </div>
                        )
                      } 
                      return (<div key={i} />)
                    })
                  }
                </div>
              )
            })
          }
        </div>
      </div>

      <Modal
        isFullScreen
        hasCornerCloseButton={false}
        closeFunc={toggleMobileFilterModal}
        isActive={isMobileFilterOpen}
        className={styles.mobileFilterModal}
        fullScreenWrapperClass={styles.mobileFilterModalInner}
      >
        <div className={styles.mobileFilterModalCloseButton}>
          <Button styleType='text' onClick={toggleMobileFilterModal}>
            <CloseSquared height={18} width={18} className={styles.mobileFilterModalCloseIcon} /> Close filters
          </Button>
        </div>
          
        <div>
          <h3 className={styles.mobileModalHeading}>Filter Personal Trainers</h3>
        </div>

        {!displayedPt?.length && 
        <div className={styles.mobileFilterNoResults}>
          <h2>No results found</h2>
          <p>Please try adjusting your filters.</p>
          <div><Button styleType='text' onClick={clearFilters}>Clear filters</Button></div>
        </div>
        }

        {displayedPt && displayedPt.length > 0 && 
        <>
          <div className={styles.mobileModalFilterWrapper}>
            <Filters ptList={displayedPt} unfilteredPtList={ptList} />
          </div>

          <div className={styles.mobileFilterControls}>
            <div><Button styleType='text' onClick={clearFilters}>Clear filters</Button></div>
            <div><Button styleType='primary' onClick={toggleMobileFilterModal}>View {displayedPt?.length} trainers</Button></div>
          </div>
        </>
        }
      </Modal>
    </>
  )
}

export default PersonalTrainerLister