import * as React from "react";

const defaultColour = '#5F6062'

const SvgNewWindow = (props) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"    
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    fill="none"
  >
    <path
      d="M21 3H22V2H21V3ZM10.2929 12.2929C9.90237 12.6834 9.90237 13.3166 10.2929 13.7071C10.6834 14.0976 11.3166 14.0976 11.7071 13.7071L10.2929 12.2929ZM13 4H21V2L13 2V4ZM20 3V11H22V3H20ZM20.2929 2.29289L10.2929 12.2929L11.7071 13.7071L21.7071 3.70711L20.2929 2.29289Z"
      fill={defaultColour}
    />
    <path d="M9 4V4C7.13077 4 6.19615 4 5.5 4.40192C5.04394 4.66523 4.66523 5.04394 4.40192 5.5C4 6.19615 4 7.13077 4 9V14C4 16.8284 4 18.2426 4.87868 19.1213C5.75736 20 7.17157 20 10 20H15C16.8692 20 17.8038 20 18.5 19.5981C18.9561 19.3348 19.3348 18.9561 19.5981 18.5C20 17.8038 20 16.8692 20 15V15"
     stroke={defaultColour} strokeWidth={2}
     strokeLinecap="round" />
  </svg>
);

export default SvgNewWindow;
