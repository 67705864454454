import AppRoutes from './routes'
import cookies from 'js-cookie'
import { useContext, useEffect } from 'react'
import useGetBasket from './hooks/useGetBasket'
import styles from './App.module.scss'
import useGetPlaces from './hooks/useGetPlaces'
import { appContext } from './context'

const userCookieName = process.env.REACT_APP_USER_COOKIE_NAME || ''

const App = () => {
  // SAMPLE COOKIE FOR DEV ONLY. Set cookie value in .env.local
  if(process.env.NODE_ENV === 'development') {
    cookies.remove(userCookieName)
    cookies.set(
      userCookieName,
      process.env.REACT_APP_DUMMY_USER_COOKIE || '',
      { expires: 0.1 },
    )
  }

  const context = useContext(appContext)
  const { getBasket } = useGetBasket()
  const { getPlacesData } = useGetPlaces(context?.gymId)
  
  useEffect(() => {
    getBasket()

    // Inject Adobe launch script into head
    let script: any
    if (process.env.REACT_APP_ADOBE_LAUNCH_SCRIPT) {
      script = document.createElement('script')

      script.src = process.env.REACT_APP_ADOBE_LAUNCH_SCRIPT
      script.async = true
      script.id = 'adobe-launch-script'

      document.head.appendChild(script)
    }
    
    return () => {
      // Remove on unmount of SPA
      if (script) {
        document.head.removeChild(script)
      }
    }
  }, [])

  // Get gym details only if there is gym ID and whenever the gym ID changes
  useEffect(() => {
    if (context?.gymId) {
      getPlacesData()
    }
  }, [context?.gymId])
  
  return (
    <div className={styles.App}>      
      <AppRoutes />
    </div>
  )
}

export default App
