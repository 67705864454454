import { useContext, useEffect, useState } from 'react'
import { appContext } from '../context'
import { getOid, getUserToken } from '../utils/getUserData'
import axios, { AxiosError, AxiosResponse } from 'axios'

const userToken = getUserToken()
const customerId = getOid()
// const customerId = '437aa7ad-85fc-443f-af3c-f1697911d903'

const useRemoveItem = () => {
  const context = useContext(appContext)

  const [response, setResponse] = useState<AxiosResponse>()
  const [error, setError] = useState<AxiosError | unknown>()
  const [loading, setLoading] = useState(false)  

  const removeItemFromBasket = async (itemId?:(string | null)) => {
    setLoading(true)
    try {
      const res = await axios.request({
        method: 'POST',
        url: `/basket/basket/${customerId}/remove`,
        headers: {
          'Authorization': `Bearer ${userToken}`,
          'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_APIM_SUB_KEY}`,
        },
        data: {
          'commerceItemId': itemId,
        },
      })
      setResponse(res)
    } catch( err ) {
      setError(err)
    } finally {
      setLoading(false)
    }
  }

  const addRemoveItemFromBasketHandler = (itemId?:(string | null), quantity?:number) => {
    setLoading(true)
    setTimeout(async () => {
      try {
        const res = await axios.request({
          method: 'PUT',
          url: `/basket/basket/${customerId}/items/${itemId}/quantity`,
          headers: {
            'Authorization': `Bearer ${userToken}`,
          },
          data: {
            'value': quantity,
          },
        })
        setResponse(res)
      } catch( err ) {
        setError(err)
      } finally {
        setLoading(false)
      }
    },300)
  }

  useEffect(() => {
    if (response?.data && JSON.stringify(response?.data) !== JSON.stringify(context?.basket)) {
      const responseData: BasketType = response?.data
      context?.setBasket(responseData)
      context?.setTotalPrice(responseData?.price?.discountedPrice || responseData?.price?.totalPrice || 0)
    }
  }, [response])

  return {
    response,
    error,
    loading,
    removeItemFromBasket,
    addRemoveItemFromBasketHandler,
  }
}

export default useRemoveItem
