import * as React from "react";

const SvgTick = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m99.375 58.875 5.25 5.25-45.75 45.375-24-24 5.25-5.25L58.875 99l40.5-40.125ZM108 50.25l5.25 5.25-5.25 5.25-5.25-5.25 5.25-5.25Z"
      fill="#00A200"
    />
  </svg>
);

export default SvgTick;
