import { useEffect, useRef, useState } from 'react'
import style from './ProgressBar.module.scss'
import { ChevronDown } from '../icons'
import coStepsJson from './stepData.json'

interface IComponentProps {
  step: number,
  isScrolled: boolean
}

// Single source of truth for step widths for desktop. These values are picked 
// up in the CSS via variables set on the <ol>
const stepNumberWidth = 30 //30px
const stepWidthDesktop = 130 //130px

// Work out the space between the numbers in the desktop progress bar to 
// set as the width for the lines. This is stored as a CSS variable 
// (--step-list-line-between-width) which can then be used in the stylesheet.
export const utilCalculateStepLineWidth = (stepListElemWidth: number) => {
  const numberOfSteps = 5

  // Get space between .step elements
  const gapAmount = ((stepListElemWidth - (stepWidthDesktop * numberOfSteps)) / (numberOfSteps - 1))

  return (gapAmount / 2) + (stepWidthDesktop / 2) - (stepNumberWidth / 2)
}

const ProgressBar = ({ step, isScrolled } : IComponentProps) => {
  const stepListElem = useRef<HTMLOListElement>(null)
  const [stepLineWidth, setStepLineWidth] = useState(0)
  const stepArrayPosition = step - 1
  const coSteps = coStepsJson

  useEffect(() => {
    // Work out length of lines between steps
    const setLineLength = () => {
      if (stepListElem.current) {
        const stepListElemWidth = stepListElem.current ? stepListElem.current.offsetWidth : 0
        setStepLineWidth(utilCalculateStepLineWidth(stepListElemWidth))
      }
    }
    
    // Fire initially
    setLineLength()

    // Event listener - adjust spacebetween steps on window resize
    window.addEventListener('resize', setLineLength)

    return () => {
      // Clean up event listener on component unmount
      window.removeEventListener('resize', setLineLength)
    }
  }, [])

  coSteps[stepArrayPosition].active = true

  return (
    <div className={`${style.progressBar}  ${isScrolled && style.isScrolled}`} data-testid='progress-bar'>
      <ol
        ref={stepListElem}
        className={style.stepList}
        style={{
          '--step-list-line-between-width': `${stepLineWidth}px`,
          '--desktop-step-number-width': `${stepNumberWidth}px`,
          '--desktop-step-width': `${stepWidthDesktop}px`,
        } as React.CSSProperties}
        data-testid='progress-bar-step-list'
      >
        {coSteps.map((coStep, i) => {
          const isCurrentStep = stepArrayPosition === i

          // Checkout if user has gone past this step..
          const isStepComplete = i < stepArrayPosition

          return (
            <li
              className={`
                ${style.step}
                ${isCurrentStep ? style.active : ''}
                ${isStepComplete ? style.complete : ''}
              `}
              key={coStep.mobileText}
              data-testid={`progress-bar-step-item-${coStep.mobileText}`}
            >
              <div className={style.stepNumber} data-testid='progress-bar-step-number'>{i + 1}</div>            
              <div className={style.chevronWrapper}>
                <ChevronDown width={30} height={30} className={style.activeChevron} />
              </div>
              <span className={style.mobileCopy}>{coStep.mobileText}</span>
              <span className={style.desktopCopy}>{coStep.desktopText}</span>
              <div className={style.stepUnderLine}></div>
            </li>
          )
        })}
      </ol>
    </div>
  )
}

export default ProgressBar