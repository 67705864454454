import { useEffect, useState } from 'react'
import { Location, Search } from '../icons'
import styles from './LocationFinder.module.scss'
import GoogleAutocomplete from '../GoogleAutocomplete'
import LocationList from './LocationList'
import { getPosition } from  '../../utils/getGeolocation'
import useGetPlaces from '../../hooks/useGetPlaces'
import Button from '../Button'
import { FullPageLoader } from '../Loader'
import NotificationCard from '../NotificationCard'

const LocationFinder = () => {
  const [showGyms, setShowGyms] = useState<boolean>(false)
  const [selectedCoords, setSelectedCoords] = useState<coordsType | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isGeolocationBlocked, setIsGeolocationBlocked] = useState(false)

  const {
    placesData,
    getPlacesData,
  } = useGetPlaces() 

  useEffect(() => {
    // Get all places (gyms)
    getPlacesData()
  }, [])

  const getAutocompleteData = (addressData: google.maps.GeocoderResult) => {
    const coords = {
      lat: addressData.geometry.location.lat(),
      lng: addressData.geometry.location.lng(),
    }
    setSelectedCoords(coords)
    setShowGyms(true)
  }

  const getGeolocationPosition = () => {
    setIsLoading(true)
    setIsGeolocationBlocked(false)

    const successCallback = (locationData: GeolocationPosition) => {
      setIsLoading(false)
      setIsGeolocationBlocked(false)

      setSelectedCoords({
        lat: locationData.coords.latitude,
        lng: locationData.coords.longitude,
      })

      setShowGyms(true)
    }

    const errorCallback = (err: GeolocationPositionError) => {
      console.warn(`A geolocation error occurred: ${err.code} ${err.message}`)

      // Error code 1 is "permission denied"
      if (err.code === 1) {
        setIsGeolocationBlocked(true)
      }
      
      setIsLoading(false)
    }
    
    getPosition(successCallback, errorCallback)
  }

  return (
    <div data-testid='location-finder-component'>
      <FullPageLoader isLoading={isLoading} setIsLoading={setIsLoading} />
      <div className={styles.searchView}>
        <h1 data-testid='location-finder-main-title'>Search for a gym location</h1>
        <p>Changing locations will result in different personal trainers and package rates. Purchasing personal training at a gym other than your home gym may incur additional charges.</p>
        <p>Enter a postcode or area to explore our personal training packages. Please be aware that all current progress will be lost.</p>

        <div className={styles.searchFormWrapper}>
          <div className={styles.searchBarWrapper}>
            <GoogleAutocomplete getResponseData={getAutocompleteData} />
            <Search width={30} height={30} />
          </div>

          <Button
            styleType='text'
            type='button'
            testId="geolocation-button"
            className={styles.geolocationBtn}
            onClick={getGeolocationPosition}
          >
            <Location height={25} width={25} /> Use my current location
          </Button>

          {isGeolocationBlocked &&
          <NotificationCard type='warning' heading='To search using your location, enable location services in your browser settings.'>
          </NotificationCard>
          }
        </div>
      </div>
      {showGyms &&
        <>
          <hr className={styles.gymResultsHr} /> 
          <LocationList
            userCoords={selectedCoords}
            places={placesData?.data?.locations}
          />
        </>
      }
    </div>
  )
}

export default LocationFinder