import { LinkButton } from '../Button'
import { Edit } from '../icons'
import styles from './CartEditLink.module.scss'

const EditLink = ({
  editTestId,
  editLink,
  isHrefLink = false,
}: {
  editTestId: string;
  editLink: string;
  isHrefLink?: boolean;
}) => (
  <LinkButton
    testId={editTestId}
    isButtonStyle
    buttonStyleType='text'
    isHref={isHrefLink}
    link={editLink}
  >
    <span className={styles.editButtonText}>Edit</span>
    <span className={styles.editIcon}>
      <Edit width={29} height={31} />
    </span>
  </LinkButton>
)

export default EditLink