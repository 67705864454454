import * as React from "react";

const SvgEdit = (props) => (
  <svg
    width="29"
    height="31"
    viewBox="0 0 29 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.6517 19.1817L21.2129 0L28.3807 7.82927L10.81 27.0214L6.36114 27.0788L6.34322 25.2324L10.1028 25.1841L18.2263 16.311L13.4477 11.0915L5.32419 19.9646L5.28716 24.0607L3.59555 24.0398L3.6517 19.1817ZM21.8102 12.3963L25.9914 7.82927L21.2129 2.60976L17.0317 7.17683L21.8102 12.3963ZM14.6424 9.78659L15.837 8.48171L20.6156 13.7012L19.4209 15.0061L14.6424 9.78659ZM5.2776 27.0801L5.2788 25.2337L3.5836 25.2403L3.5836 27.0854L5.2776 27.0801Z"
      fill={props.fill || '#00A200'}
    />
  </svg>
);

export default SvgEdit;
