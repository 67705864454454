import * as React from "react";

const SvgCross = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m102.375 97.125 5.25 5.25-5.25 5.25-5.25-5.25 5.25-5.25Zm0-54.75 5.25 5.25L80.25 75 99 93.75 93.75 99 75 80.25l-27.375 27.375-5.25-5.25L69.75 75 42.375 47.625l5.25-5.25L75 69.75l27.375-27.375Z"
      fill={props.fill || '#00A200'}
    />
    <path
      d="M138.75 75c0 35.25-28.5 63.75-63.75 63.75S11.25 110.25 11.25 75 39.75 11.25 75 11.25s63.75 28.5 63.75 63.75Zm-7.5 0c0-31.125-25.125-56.25-56.25-56.25S18.75 43.875 18.75 75 43.875 131.25 75 131.25s56.25-25.125 56.25-56.25Z"
      fill={props.fill || '#00A200'}
    />
  </svg>
);

export default SvgCross;
