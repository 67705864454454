import { useContext, useEffect } from 'react'
import { appContext } from '../context'
import { getOid, getUserToken } from '../utils/getUserData'
import useFetch from './useFetch'

const userToken = getUserToken()
const customerId = getOid()

const useAddToBasket = (postData: GenericObject) => {
  const context = useContext(appContext)

  // If no item is in the cart, request type is POST.
  // If there is an item in the cart, request type is PUT.
  const requestMethod = (context?.basket?.commerceItems && context?.basket?.commerceItems?.length > 0) ? 'PUT' : 'POST'

  const {
    response: addToCartResp,
    loading,
    error,
    fetchData,
  } = useFetch({
    method: requestMethod,
    url: `/basket/basket/${customerId}/add`,
    headers: {
      'Authorization': `Bearer ${userToken}`,
    },
    data: postData,
  })
  
  const postToBasket = () => {
    fetchData()
  }

  useEffect(() => {
    if (addToCartResp?.data && JSON.stringify(addToCartResp?.data) !== JSON.stringify(context?.basket)) {      
      const responseData: BasketType = addToCartResp?.data
      context?.setBasket(responseData)
      context?.setTotalPrice(responseData?.price?.discountedPrice || responseData?.price?.totalPrice || 0)

      if (responseData?.basketId) {
        context?.setBasketIdForPay(responseData?.basketId)
      }
    }
  }, [addToCartResp])

  return {
    addToCartResp,
    error,
    loading,
    postToBasket,
  }
}

export default useAddToBasket
