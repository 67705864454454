import Portal from '../Portal'
import { IComponentProps } from './types'
import style from './FullPageLoader.module.scss'
import Loader from './Loader'

const Modal = ({
  isLoading = false,
  loaderSize,
  lineSize,
  text,
  setIsLoading,
}: IComponentProps) => {
  return (
    <Portal wrapperId='portalLoader'>
      {isLoading &&
        <div className={style.root}>
          <Loader
            text={text}
            isLoading={isLoading}
            loaderSize={loaderSize}
            lineSize={lineSize}
            hasBackground={false}
            setIsLoading={setIsLoading}
          />
        </div>
      }
    </Portal>
  )
}

export default Modal