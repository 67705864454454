import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useMemo,
  useState,
} from 'react'
import { useLocalStorage } from 'usehooks-ts'
import { TypeActiveMethod } from './routes/Pay/types'

type typeGymId = string | undefined
type typeSeniority = string | undefined
type typeNextPrevLink = string
type typeDiscountAmount = number
type typePrice = number
type typeBasketIdForPay = string | null

interface IAppContext {
  nextStepLink: typeNextPrevLink;
  setNextStepLink: Dispatch<SetStateAction<typeNextPrevLink>>;
  prevStepLink: typeNextPrevLink;
  setPrevStepLink: Dispatch<SetStateAction<typeNextPrevLink>>;
  totalPrice: typePrice;
  setTotalPrice: Dispatch<SetStateAction<typePrice>>;
  currentSelectionPrice: typePrice;
  setCurrentSelectionPrice: Dispatch<SetStateAction<typePrice>>;
  discountAmount: typeDiscountAmount;
  setDiscountAmount: Dispatch<SetStateAction<typeDiscountAmount>>;
  packageFormValue: PackageFormValues;
  setPackageFormValue: Dispatch<SetStateAction<PackageFormValues>>;
  currencySymbol: string;
  gymId: typeGymId;
  setGymId: Dispatch<SetStateAction<typeGymId>>;
  selectedGymData: IGymLocationData | null;
  setSelectedGymData: Dispatch<SetStateAction<IGymLocationData | null>>;
  locationApiFailure: any;
  setLocationApiFailure: Dispatch<SetStateAction<any>>;
  ptSeniority: typeSeniority;
  setPtSeniority: Dispatch<SetStateAction<typeSeniority>>;
  selectedPersonalTrainer: IPersonalTrainerData | null;
  setSelectedPersonalTrainer: Dispatch<SetStateAction<IPersonalTrainerData | null>>;
  basket: BasketType;
  setBasket: Dispatch<SetStateAction<BasketType>>;
  basketError: ErrorResponseType;
  setBasketError: Dispatch<SetStateAction<ErrorResponseType>>;
  orderRefNumber: string | undefined;
  setOrderRefNumber: Dispatch<SetStateAction<string | undefined>>;
  persistentPayMethod: TypeActiveMethod;
  setPersistentPayMethod: Dispatch<SetStateAction<TypeActiveMethod>>;
  basketIdForPay: typeBasketIdForPay;
  setBasketIdForPay: Dispatch<SetStateAction<typeBasketIdForPay>>;
}

interface IMockContextValueObj {
  currentSelectionPrice?: typePrice;
  totalPrice?: typePrice;
  discountAmount?: typePrice;
  gymId?: typeGymId;
  selectedGymData?: IGymLocationData;
  selectedPersonalTrainer?: IPersonalTrainerData;
  ptSeniority?: typeSeniority;
  basket?: BasketType;
  basketError?: ErrorResponseType;
  orderRefNumber?: string;
  basketIdForPay?: string;
}

interface IAppContextComponentProps {
  children: ReactNode;
  overrideDefaultValues?: IMockContextValueObj;
}
 
export const appContext = createContext<IAppContext | null>(null)

const AppContext = ({
  children,
  overrideDefaultValues, // Used mainly for tests to set state values to mock scenarios
}: IAppContextComponentProps) => {
  const [packageFormValue, setPackageFormValue] = useState<PackageFormValues>()
  const [currentSelectionPrice, setCurrentSelectionPrice] = useState<typePrice>(0)
  const [totalPrice, setTotalPrice] = useState<typePrice>(0)
  const [discountAmount, setDiscountAmount] = useState<typeDiscountAmount>(0)
  const [nextStepLink, setNextStepLink] = useState<typeNextPrevLink>('/')
  const [prevStepLink, setPrevStepLink] = useState<typeNextPrevLink>('/')
  const [gymId, setGymId] = useState<typeGymId>('') // home gym is default
  const [ptSeniority, setPtSeniority] = useLocalStorage<typeSeniority>('ptSeniority', '')
  const [selectedPersonalTrainer, setSelectedPersonalTrainer] = useState<IPersonalTrainerData | null>(overrideDefaultValues?.selectedPersonalTrainer ?? null)
  const [persistentPayMethod, setPersistentPayMethod] = useLocalStorage<TypeActiveMethod>('payment-method', null)
  const [basketIdForPay, setBasketIdForPay] = useLocalStorage<typeBasketIdForPay>('basket-id-for-pay', overrideDefaultValues?.basketIdForPay ?? null)
  
  const [basket, setBasket] = useState<BasketType | undefined>(overrideDefaultValues?.basket)
  const [basketError, setBasketError] = useState<ErrorResponseType | undefined>(overrideDefaultValues?.basketError)
  
  const [selectedGymData, setSelectedGymData] = useState<IGymLocationData | null>(overrideDefaultValues?.selectedGymData ?? null)
  const [orderRefNumber, setOrderRefNumber] = useState<string | undefined>(undefined)

  const [locationApiFailure, setLocationApiFailure] = useState<any>(null)

  const currencySymbol = '£'

  const providerValue= useMemo(() => ({ 
    nextStepLink,
    setNextStepLink,
    prevStepLink,
    setPrevStepLink,
    totalPrice,
    setTotalPrice,
    packageFormValue,
    setPackageFormValue,
    currencySymbol,
    gymId,
    setGymId,
    ptSeniority,
    setPtSeniority,
    discountAmount,
    setDiscountAmount,
    currentSelectionPrice,
    setCurrentSelectionPrice,
    basket,
    setBasket,
    basketError,
    setBasketError,
    orderRefNumber,
    setOrderRefNumber,
    selectedPersonalTrainer,
    setSelectedPersonalTrainer,
    selectedGymData,
    setSelectedGymData,
    persistentPayMethod,
    setPersistentPayMethod,
    locationApiFailure, 
    setLocationApiFailure,
    basketIdForPay,
    setBasketIdForPay,
  }), [
    nextStepLink,
    setNextStepLink,
    prevStepLink,
    setPrevStepLink,
    totalPrice,
    setTotalPrice,
    packageFormValue,
    setPackageFormValue,
    currencySymbol,
    gymId,
    setGymId,
    ptSeniority,
    setPtSeniority,
    discountAmount,
    setDiscountAmount,
    currentSelectionPrice,
    setCurrentSelectionPrice,
    basket,
    setBasket,
    basketError,
    setBasketError,
    orderRefNumber,
    setOrderRefNumber,
    selectedPersonalTrainer,
    setSelectedPersonalTrainer,
    selectedGymData,
    setSelectedGymData,
    persistentPayMethod,
    setPersistentPayMethod,
    locationApiFailure, 
    setLocationApiFailure,
    basketIdForPay,
    setBasketIdForPay,
  ])

  return (
    <appContext.Provider value={providerValue}>
      { children }
    </appContext.Provider>
  )
}

export default AppContext