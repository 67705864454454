import { isUserValid } from '../utils/getUserData'
import useFetch from './useFetch'

const useGetPeople = (locationId: string) => {
  const {
    response: peopleData,
    setResponse: setPeopleData,
    loading,
    setError,
    error,
    fetchData,
  } = useFetch({
    method: 'GET',
    url: `/people/people?location=${locationId}`,    
  })

  const getPeopleData = () => {
    setPeopleData(undefined)
    setError(undefined)
    if (isUserValid()) {
      fetchData()
    }
  }

  return {
    peopleData,
    error,
    loading,
    getPeopleData,
  }
}

export default useGetPeople
