import * as React from "react";

const SvgLocation = (props) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 200 200"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M134.28 81.2A34.28 34.28 0 1 0 100 115.48a34.32 34.32 0 0 0 34.28-34.28ZM100 105.48a24.28 24.28 0 1 1 24.28-24.28A24.3 24.3 0 0 1 100 105.48Zm69.74-24.28c0 14-7.74 32.35-23 54.45a326.39 326.39 0 0 1-35.1 42.35l-7.07-7.08c21.33-21.29 55.17-64.46 55.17-89.68a59.74 59.74 0 0 0-119.48 0c0 25.09 33.87 68.3 55.22 89.64l-7.07 7.07a332.28 332.28 0 0 1-34.93-42.14C38.07 113.52 30.26 95.16 30.26 81.2a69.74 69.74 0 0 1 139.48 0ZM100 175.37l7.07 7.07-7.07 7.07-7.07-7.07Z"
      style={{
        fill: "#00a200",
      }}
    />
  </svg>
);

export default SvgLocation;
