import { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import Body from '../../components/Body'
import Header from '../../components/Header'
import CartSummary from '../../components/CartSummary'
import pageStyle from '../PageContent.module.scss'
import { LinkButton } from '../../components/Button'
import { appContext } from '../../context'
import styles from './ReviewConfirm.module.scss'
import Modal from '../../components/Modal'
import EmptyCart from '../../components/CartSummary/EmptyCart'
import URL from '../../constants/route-urls'
import { getHomeGym } from '../../utils/getUserData'
import useDataLayer from '../../hooks/useDataLayer'

const ReviewConfirm = () => {
  const context = useContext(appContext)
  const { dataLoadedEvent, getCartItemsForDataLayer } = useDataLayer()
  const [isCheckoutBtnEnabled, setIsCheckoutBtnEnabled] = useState(false)

  useEffect(() => {
    // In this case no gym previously selected we use the home gym.
    if (!context?.gymId || context?.gymId === '') {
      context?.setGymId(getHomeGym())
    }
  }, [])

  useEffect(() => {
    if (!context?.basket) {
      return
    }
    
    dataLoadedEvent({
      pageType: 'Cart',
      pageName: 'Review and Confirm',
      pageUrl: `${window.location.origin}/${URL.CART}`,
      pageTitle: 'Review & Confirm',
    }, {
      action: 'checkout',
      step: 1,
      coupon: '',
      couponType: '',
    }, 
    getCartItemsForDataLayer(),
    )
  }, [context?.basket])

  const tcCheckboxCallback = (tcAgreed: boolean) => {
    setIsCheckoutBtnEnabled(tcAgreed)
  }

  return (
    <>
      {/* Head */}
      <Helmet>
        <title>Review &amp; Confirm</title>
      </Helmet>

      <Header title="Review and confirm" step={3} />
      <Body>
        <section className={`${pageStyle.pageSection} ${pageStyle.introText}`} aria-label='Cart summary'>
          {
            (context?.basket?.numberOfItems! < 1) ? (
              <EmptyCart />
            ) : (
              <CartSummary
                showDiscountCodeForm
                showTCbox
                editableLocation={true}
                editablePT={true}
                editablePackage={true}
                tcCheckboxCallback={tcCheckboxCallback}
              />
            )
          }
        </section>

        <LinkButton
          testId='checkout-button'
          className={styles.checkoutBtn}
          isButtonStyle
          isInline
          link={`/${URL.PAY_BY_CARD}`}
          disabled={!isCheckoutBtnEnabled || context?.basket?.numberOfItems! < 1}
        >
          Confirm and pay
        </LinkButton>
        <Modal
          isActive={context?.basket?.numberOfItems! < 1}
          hasCornerCloseButton={false}
          title='Cart empty'
          modalWidth='75%'
        >
          <div className={styles.modalContent} data-testid="empty-cart-modal-content">
            <p>There is nothing in your cart.</p>
            <LinkButton
              testId='go-to-homepage-button'
              isButtonStyle
              link={`/${URL.SELECT_PERSONAL_TRAINER}`}
            >
              Select a personal trainer
            </LinkButton>
          </div>
        </Modal>
      </Body>
    </>
  )
}

export default ReviewConfirm