import * as React from "react";

const SvgProfile = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 2.115a4.665 4.665 0 1 0 4.657 4.635A4.657 4.657 0 0 0 12 2.115Zm0 7.98a3.239 3.239 0 0 1-3.247-3.248A3.3 3.3 0 0 1 12 3.533a3.233 3.233 0 0 1 3.248 3.218A3.3 3.3 0 0 1 12 10.095ZM12 0a12 12 0 1 0 0 24 12 12 0 0 0 0-24ZM5.01 19.98a7.26 7.26 0 0 1 13.98 0 10.665 10.665 0 0 1-13.98 0Zm15.105-1.133a8.692 8.692 0 0 0-16.23 0 10.59 10.59 0 1 1 16.23 0ZM12 15.668l.99.99-.99.99-.99-.99.99-.99Zm0 3.083.99.983-.99 1.02-.99-.99.99-1.013Z"
      fill="#00A200"
    />
  </svg>
);

export default SvgProfile;
