import { ChangeEvent, useContext } from 'react'
import Radio from '../../components/Radio'
import { appContext } from '../../context'
import Badge from '../../components/Badge'
import style from './PackageOption.module.scss'
import useBreakpoints from '../../hooks/useBreakpoints'

interface IComponentProps {
  value: IPackageVariantServiceData;
  details?: string | null;
  price: number | null;
  wasPrice?: number | null;
  offPercentage?: number | null;
  size: number | null;
  checked?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  inputTestId?: string;
}

const PackageOption = ({
  value,
  price,
  wasPrice,
  offPercentage,
  size,
  checked = false,
  onChange,
  inputTestId,
}: IComponentProps) => {

  const context = useContext(appContext)
  const { screenFromTablet } = useBreakpoints()

  const pricePerSessions = (price && size) ? price / size : 0
  const wasPriceStr = wasPrice?.toFixed(2).toString()

  if (size) {
    return (
      <div className={`${style.root} ${checked ? style.optionChecked : ''}`} data-testid='package-option-wrapper'>
        <Radio
          name='package'
          value={JSON.stringify(value)}
          onChange={onChange}
          checked={checked}
          inputTestId={inputTestId}
        >
          <div className={style.option} data-testid='package-option'>
            <div className={style.optionRow}>
              <div className={style.optionTitle}>{size} hours</div>
              <div className={style.topRight}>
                {wasPrice &&
                  <span className={style.wasPrice} data-testid='package-option-wasPrice'>{context?.currencySymbol}{wasPriceStr?.replace(/\.00$/, '')}</span>
                }

                {!screenFromTablet && !wasPrice && pricePerSessions > 0 &&
                  <div className={style.optionDetails}>
                    {context?.currencySymbol}{pricePerSessions.toFixed(2)} per hour    
                  </div>
                }

                {screenFromTablet && !wasPrice &&
                  <div className={style.price} data-testid='package-option-price'>
                    <strong>{context?.currencySymbol}{price?.toFixed(2).replace(/\.00$/, '')}</strong>
                  </div>
                }
              </div>
            </div>

            <div className={style.optionRow}>
              <div className={style.optionDetails}>
                {!screenFromTablet && wasPrice && pricePerSessions > 0 &&
                  <>{context?.currencySymbol}{pricePerSessions.toFixed(2)} per hour</>
                }

                {screenFromTablet && pricePerSessions > 0 &&
                  <div className={style.optionDetails}>
                    {context?.currencySymbol}{pricePerSessions.toFixed(2)} per hour
                  </div>
                }
              </div>

              <div className={`${style.priceWrapper} ${wasPrice ? style.hasWasPrice : ''}`}>
                <div className={style.badges}>
                  {offPercentage &&
                    <div className={style.badgeWrapper}>
                      <Badge bgColor='#FFC82C'>
                        <span className={`${style.discountBadgeText} small-text`}>{offPercentage}%</span>
                      </Badge>
                    </div>
                  }

                  <div className={style.badgeWrapper}>
                    <Badge bgColor='#E3EDE2'>
                      <span className={`${style.discountBadgeText} small-text`}>Online Discount Applied</span>
                    </Badge>
                  </div>
                </div>
                
                {screenFromTablet && wasPrice &&
                  <div className={style.price} data-testid='package-option-price'>
                    <strong>{context?.currencySymbol}{price?.toFixed(2).replace(/\.00$/, '')}</strong>
                  </div>
                }

                {!screenFromTablet && 
                  <div className={style.price} data-testid='package-option-price'>
                    <strong>{context?.currencySymbol}{price?.toFixed(2).replace(/\.00$/, '')}</strong>
                  </div>
                }
              </div>              
            </div>
          </div>
        </Radio>
      </div>
    )
  } else {
    return <div className={style.root}>No value</div>
  }

}

export default PackageOption