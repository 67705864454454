import cookies from 'js-cookie'

const getUserCookie = (): GenericObject => {
  const cookieValue = cookies.get('NuffLoggedInRememberMe')
  if (!cookieValue) {
    return {}
  }
  
  return JSON.parse(decodeURIComponent(cookies.get('NuffLoggedInRememberMe') || ''))
}


export default getUserCookie