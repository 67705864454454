import * as React from "react";

const SvgChevron2Up = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m12 9 .707-.707L12 7.586l-.707.707L12 9Zm6.707 5.293-6-6-1.414 1.414 6 6 1.414-1.414Zm-7.414-6-6 6 1.414 1.414 6-6-1.414-1.414Z"
      fill={props.fill || '#CCD2E3'}
    />
  </svg>
);

export default SvgChevron2Up;
