import { useScript } from 'usehooks-ts'
import GoogleAutocomplete from './GoogleAutocomplete'

interface IComponentProps {
  getResponseData?(results: google.maps.GeocoderResult): void;
}

const ScriptLoaderWrapper = ({ getResponseData }: IComponentProps) => {
  const mapsLoadedStatus = useScript(`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GMAPS_KEY}&libraries=places`)

  return (
    <>
      {mapsLoadedStatus === 'ready' &&
        <GoogleAutocomplete getResponseData={getResponseData} />
      }
    </>
  )
}

export default ScriptLoaderWrapper