import * as React from "react";

const SvgArrowLeft = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M116.25 78.75h-73.5l23.625 23.625-5.25 5.25L28.5 75l24-24 5.25 5.25-15 15h73.5v7.5ZM66.375 47.625l-5.25-5.25-5.25 5.25 5.25 5.25 5.25-5.25Z"
      fill="#00A200"
    />
  </svg>
);

export default SvgArrowLeft;
