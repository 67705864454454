import * as React from "react";

const SvgPaymentKlarna = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 40 26"
    {...props}
  >
    <path fill="#fff" d="M0 0h40v26H0z" />
    <g clipPath="url(#payment-klarna_svg__a)">
      <path
        fill="#FFB3C7"
        d="M34.54 3H5.46A3.46 3.46 0 0 0 2 6.46v13.08A3.46 3.46 0 0 0 5.46 23h29.08A3.46 3.46 0 0 0 38 19.54V6.46A3.46 3.46 0 0 0 34.54 3Z"
      />
      <path
        fill="#0A0B09"
        d="M34.63 14.72a.86.86 0 0 0-.85.86c0 .47.38.86.85.86.48 0 .86-.39.86-.86a.86.86 0 0 0-.86-.86Zm-2.8-.67a1.2 1.2 0 0 0-1.23-1.18 1.2 1.2 0 0 0-1.23 1.18c0 .65.55 1.18 1.23 1.18a1.2 1.2 0 0 0 1.23-1.18Zm0-2.29h1.36v4.58h-1.36v-.3a2.3 2.3 0 0 1-1.34.43 2.4 2.4 0 0 1-2.4-2.42 2.4 2.4 0 0 1 3.74-2v-.29Zm-10.87.6v-.6h-1.4v4.58h1.4V14.2c0-.72.78-1.1 1.32-1.1h.01v-1.34c-.55 0-1.06.24-1.33.6Zm-3.47 1.7a1.2 1.2 0 0 0-1.23-1.19 1.2 1.2 0 0 0-1.23 1.18c0 .65.55 1.18 1.23 1.18a1.2 1.2 0 0 0 1.23-1.18Zm0-2.3h1.37v4.58H17.5v-.3c-.38.27-.85.43-1.35.43a2.4 2.4 0 0 1-2.4-2.42 2.4 2.4 0 0 1 3.75-2v-.29Zm8.19-.12c-.54 0-1.06.17-1.4.64v-.52h-1.36v4.58h1.37v-2.4c0-.7.47-1.04 1.02-1.04.6 0 .94.36.94 1.03v2.41h1.36v-2.91c0-1.07-.84-1.8-1.93-1.8Zm-13.9 4.7h1.42V9.72h-1.42v6.62Zm-6.25 0h1.5V9.72h-1.5v6.62Zm5.27-6.62a5.3 5.3 0 0 1-1.54 3.76l2.08 2.86H9.48l-2.26-3.11.58-.44a3.81 3.81 0 0 0 1.52-3.07h1.48Z"
      />
    </g>
    <defs>
      <clipPath id="payment-klarna_svg__a">
        <path fill="#fff" d="M0 0h36v20H0z" transform="translate(2 3)" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgPaymentKlarna;
