import { useContext, useEffect } from 'react'
import useDataLayer, { IDataLayerPageData } from '../../hooks/useDataLayer'
import { appContext } from '../../context'

// Use this component to trigger the dataLoaded event for the data layer
const DataLoadedEvent = ({
  pageType,
  pageName,
  pageUrl,
  pageTitle,
}: IDataLayerPageData) => {
  const context = useContext(appContext)
  const { dataLoadedEvent } = useDataLayer()
  
  useEffect(() => {
    if (context?.basket) {
      dataLoadedEvent({
        pageType,
        pageName,
        pageUrl,
        pageTitle,
      })
    }
  }, [context?.basket])

  return null
}

export default DataLoadedEvent