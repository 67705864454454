import * as React from "react";

const SvgNuffieldShield = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 26 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.155 15.954h-9.547v12.507c-.084.032-.176.07-.257.1-.693.252-1.663.555-2.35.696-.688-.14-1.658-.444-2.345-.693-.084-.032-.177-.07-.264-.104V15.954H.846V10.98h9.546V1.028l.047-.003a39.159 39.159 0 0 1 5.12 0l.05.003v9.952h9.546v4.974ZM15.608.277a40.097 40.097 0 0 0-5.216 0C7.852.447 3.803.963.097 2.404V16.58c0 .065.004.129.005.194.114 7.544 6.549 11.096 10.29 12.49h.002c.754.275 1.852.618 2.607.755.754-.137 1.852-.48 2.606-.754h.001c3.742-1.395 10.177-4.947 10.29-12.492.002-.064.006-.128.006-.193V2.404C22.198.963 18.183.447 15.608.277Z"
      fill="#fff"
    />
  </svg>
);

export default SvgNuffieldShield;
