import {
  ChangeEvent,
  CSSProperties,
  HTMLInputTypeAttribute,
  ReactNode,
} from 'react'
import styles from './AcceptCheckbox.module.scss'

interface IComponentProps {
  children?: ReactNode;
  id: string;
  label?: string;
  name: string;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  style?: CSSProperties;
  type?: HTMLInputTypeAttribute;
  value?: string;
  isError?: boolean;
  errorMsg?: string;
  testId?: string;
}

const AcceptCheckbox = ({
  id,
  label,
  name,
  onBlur,
  onChange,
  placeholder,
  value,
  isError = false,
  errorMsg,
  testId,
  children,
}: IComponentProps) => {

  const fieldProperties = {
    id,
    name,
    onBlur,
    onChange,
    value,
    placeholder,
  }
  return (
    <div className={styles.checkboxContainer}>
      <label className={styles.labelTag} data-testid={testId}>
        <input
          type='checkbox'
          data-testid='switch-checkbox-input'
          {...fieldProperties}
        />
        {label &&
          <span>
            {label}
          </span>
        }
        {children}

      </label>
      {isError &&
        <div>{errorMsg}</div>
      }
    </div>
  )
}

export default AcceptCheckbox