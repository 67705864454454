import jwtDecode from 'jwt-decode'
import { getUserToken } from './getUserData'

const decodedUserToken = (userToken?: string): GenericObject => {
  const token = userToken ? userToken : getUserToken()
  const decodedToken: GenericObject = token ? jwtDecode<GenericObject>(token) : {}
  
  return decodedToken
}

export default decodedUserToken