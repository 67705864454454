import { ReactNode } from 'react'
import style from './Body.module.scss'

interface IComponentProps {
  children: ReactNode;
  className?: string;
}

const Body = ({ className, children }: IComponentProps) => {
  return (
    <main className={`${style.root} ${className ?? ''}`}>{children}</main>
  )
}

export default Body