import { TypeActiveMethod } from '../routes/Pay/types'

import klarnaIcon from '../assets/images/klarna-badge.png'
import cardIcon from '../assets/icons/payment-card.svg'
import googlePayIcon from '../assets/images/googlepay.png'
import applePayIcon from '../assets/images/applepay.png'
import paypalIcon from '../assets/images/paypal.png'

interface ICardBrands {
  icon: string;
  name: string;
}

type TypeCardBrands = ICardBrands[]

// Card brand user friendly name. Use the card "brand" to match against the object keys here.
const cardBrandsDisplayName = {
  amex: 'American Express',
  mc: 'MasterCard',
  maestro: 'Maestro',
  maestrouk: 'Maestro',
  visa: 'Visa',
}

/*
* paymentTypeData() used to build a paymentType object which is used to display the payment method
* on the order confirmation page.
* `adyenComponentData` is the second param in the onPaymentCompleted function which is triggered
* when a user submits a payment.
*/ 
export const paymentTypeData = (adyenComponentData: GenericObject) : IOrderPaymentType => {
  const paymentType: IOrderPaymentType = {
    type: undefined,
    displayName: undefined,
    iconUrl: undefined,
  }

  /*
  * To get card brands we need to look into a sub-object called `paymentMethod`.
  * This `paymentMethod` object only exists for the card payment type.
  */
  if (adyenComponentData?.type === 'card') {
    const cardBrand: string = adyenComponentData.data?.paymentMethod?.brand
    const cardBrands: TypeCardBrands = adyenComponentData.brands
    
    paymentType.type = cardBrand
    paymentType.displayName = cardBrandsDisplayName[cardBrand as keyof typeof cardBrandsDisplayName] ?? adyenComponentData.displayName
    paymentType.iconUrl = cardBrands.find(b => cardBrand === b.name)?.icon ?? adyenComponentData.icon

    return paymentType
  }
  
  // All other payment types can be handled here.
  paymentType.type = adyenComponentData?.type
  paymentType.displayName = adyenComponentData?.displayName
  paymentType.iconUrl = adyenComponentData?.icon

  return paymentType
}

/* 
* Function used to set the payment type object using the name of the web component containers.
* Used as a fallback in case Adyen doesn't give us any data. (This can occur when handling the
* redirect method)
*/
export const nonAdyenPaymentTypeData = (type: TypeActiveMethod): IOrderPaymentType => {
  const paymentType: IOrderPaymentType = {
    type: undefined,
    displayName: undefined,
    iconUrl: undefined,
  }

  switch (type) {
  case 'klarna':
    paymentType.type = type
    paymentType.displayName = '3 monthly instalments'
    paymentType.iconUrl = klarnaIcon
    break
  case 'card':
    paymentType.type = type
    paymentType.displayName = 'Card payment'
    paymentType.iconUrl = cardIcon
    break
  case 'paypal':
    paymentType.type = type
    paymentType.displayName = 'Paypal'
    paymentType.iconUrl = paypalIcon
    break
  case 'googlepay':
    paymentType.type = type
    paymentType.displayName = 'Google Pay'
    paymentType.iconUrl = googlePayIcon
    break
  case 'applepay':
    paymentType.type = type
    paymentType.displayName = 'Apple Pay'
    paymentType.iconUrl = applePayIcon
    break
  case 'mc':
  case 'amex':
  case 'maestro':
  case 'maestrouk':
  case 'visa':
    paymentType.type = type
    paymentType.displayName = cardBrandsDisplayName[type]
    paymentType.iconUrl = cardIcon
    break
  default: 
    paymentType.type = type
    paymentType.displayName = type
    paymentType.iconUrl = undefined
  }
  
  return paymentType
}
