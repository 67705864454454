import * as React from "react";

const SvgAlert = (props) => (
  <svg
    width="32" 
    height="32" 
    viewBox="0 0 32 32" 
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {/* <path
      d="M75 11.25c-35.25 0-63.75 28.5-63.75 63.75s28.5 63.75 63.75 63.75 63.75-28.5 63.75-63.75S110.25 11.25 75 11.25Zm0 120c-31.125 0-56.25-25.125-56.25-56.25S43.875 18.75 75 18.75 131.25 43.875 131.25 75 106.125 131.25 75 131.25Zm0-30 5.25 5.25-5.25 5.25-5.25-5.25 5.25-5.25Zm-3-6.75-.75-15v-42h7.5v42l-.75 15h-6Z"
      fill={props.fill || '#00A200'}
    /> */}
    <path d="M15.95 4.47998L2.78003 27.52H29.22L15.95 4.47998ZM15.95 7.48998L26.62 26.01H5.37003L15.95 7.49998V7.48998Z" fill="#E50707"/>
    <path d="M16.0053 21.2558L14.8032 22.4579L16.0053 23.66L17.2074 22.4579L16.0053 21.2558Z" fill="#E50707"/>
    <path d="M16.4301 20.1C16.7701 18.3 16.8701 16.8 16.8701 12.94V12.65H15.1101V12.92C15.1101 16.78 15.2201 18.29 15.5501 20.09H16.4401L16.4301 20.1Z" fill="#E50707"/>
  </svg>
);

export default SvgAlert;
