import * as React from "react";

const SvgCirclePlus = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={1}
      y={1}
      width={30}
      height={30}
      rx={15}
      stroke="#D4D4D4"
      strokeWidth={2}
    />
    <path
      d="M16 9.905v12.19M22.095 16H9.905"
      stroke={props.fill || "#00A200"}
      strokeWidth={2}
      strokeLinecap="round"
    />
  </svg>
);

export default SvgCirclePlus;
