import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useMemo,
  useState,
} from 'react'
import { IFiltersApplied } from '../PersonalTrainerFilters/types'

export interface IFilterContext {
  appliedFilters: IFiltersApplied;
  setAppliedFilters: Dispatch<SetStateAction<IFiltersApplied>>;
}

export const filterContext = createContext<IFilterContext | null>(null)

const defaultFilterState: IFiltersApplied = {
  level: [],
  specialities: [],
}

const FilterContext = ({
  children,
}: {
  children: ReactNode,
}) => {
  const [appliedFilters, setAppliedFilters] = useState<IFiltersApplied>({ ...defaultFilterState })
  const value = useMemo(() => ({ appliedFilters, setAppliedFilters }), [appliedFilters, setAppliedFilters])
  
  return (
    <filterContext.Provider value={value}>
      { children }
    </filterContext.Provider>
  )
}

export default FilterContext