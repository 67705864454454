import { ReactNode } from 'react'
import { createPortal } from 'react-dom'

interface IComponentProps {
  children: ReactNode,
  wrapperId: string
}

const Portal = ({
  children,
  wrapperId,
}: IComponentProps) => {
  return createPortal(children, document.getElementById(wrapperId) as Element)
}

export default Portal