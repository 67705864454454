import { Helmet } from 'react-helmet'
import Body from '../../components/Body'
import { LinkButton } from '../../components/Button'
import Header from '../../components/Header'
import styles from './InvalidUser.module.scss'
import pageStyle from '../PageContent.module.scss'
import { isUserValid } from '../../utils/getUserData'
import { FullPageLoader } from '../../components/Loader'
import { useNavigate } from 'react-router-dom'
import URL from '../../constants/route-urls'
import { DataLoadedEvent } from '../../components/DataLayer'

const InvalidUser = () => {
  const navigate = useNavigate()

  if (isUserValid()) {
    // If a valid user visits the invalid user page, navigate to the first step in checkout (select PT)
    navigate(`/${URL.SELECT_PERSONAL_TRAINER}`)

    return (
      <FullPageLoader isLoading />
    )
  }

  return (
    <>
      <Helmet>
        <title>Invalid User</title>
      </Helmet>
      <DataLoadedEvent
        pageType='Invalid User'
        pageName='Invalid User'
        pageUrl={`${window.location.origin}/${URL.INVALID_USER}`}
        pageTitle='Invalid User'
      />
      <Header
        title="Personal Training at Nuffield Health"
        hasBackButton={false}
      />
      <Body>
        <section className={`${pageStyle.pageSection}`}>
          <h2>Looking for a personal trainer?</h2>
          <p>Personal training is available only to gym members. Please log in or sign up for a membership to continue.</p>
          <div className={styles.pillButtons}>
            <LinkButton
              link='https://www.nuffieldhealth.com/gyms'
              isButtonStyle
              buttonStyleType='secondary'
              isHref
            >
              Join a gym
            </LinkButton>
            <LinkButton
              link='https://www.nuffieldhealth.com/account/idaaslogin'
              isButtonStyle
              buttonStyleType='primary'
              isHref
            >
              Login to buy
            </LinkButton>
          </div>
          <p>Having problems booking? <a href='https://www.nuffieldhealth.com/gyms/services/personal-training#pt-faqs'><strong>Read our Personal Training FAQs</strong></a></p>
        </section>
      </Body>
    </>
  )
}

export default InvalidUser