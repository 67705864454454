import { useContext, useState } from 'react'
import {
  getHomeGym,
  getOid,
  isUserValid,
} from '../utils/getUserData'
import { appContext } from '../context'
import { filterContext } from '../components/PersonalTrainerLister/filterContext'
import { IFiltersApplied } from '../components/PersonalTrainerFilters/types'
import trainerTypes from '../constants/trainer-types'

export interface IDataLayerPageData {  
  pageType: string;
  pageName: string;
  pageUrl: string;
  pageTitle: string;
}

interface IDataLoadedEventData {
  event: string;
  user: {
      loginStatus: string;
      customerId: any;
      homeGymid: any;
  };
  page: {
      environment: string;
      language: string;
      cartVolume: number | null | undefined;
      pageType: string;
      pageName: string;
      pageUrl: string;
      pageTitle: string;
  };
  ecommerce?: {
    currencyCode: string;
    cartId?: string | null;
    config?: {
        actionField: IDataLayerActionField;
        products: IDataLayerProduct[];
    };
  };
}

export interface IDataLayerProduct {
  serviceLine: string;
  location: string;
  clubid: string;
  itemType?: string | null;
  id?: string | null;
  name?: string | null;
  price?: number | null;
  variantId?: string | null;
  quantity?: number | null;
  size?: number | null;
  personalTrainerId?: string | null;
}

interface IDataLayerActionField {
  action: string;
  step: number;
  coupon?: string;
  couponType?: string;
  option?: string;
  type?: string; // membership type in purchase
  id?: string; // payment id in purchase
  revenue?: number | null; // total in purchase
}

const useDataLayer = () => {
  const context = useContext(appContext)
  const filtContext = useContext(filterContext)
  const [dataLayerPageData, setDataLayerPageData] = useState<IDataLayerPageData | undefined>(undefined)

  const dataLayerPush = (data: DataLayerType) => {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || []
  
      // On local dev log what data is currently being recorded.
      if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test') {
        console.log('Data layer', data)
      }
  
      // Add to dataLayer
      window.dataLayer.push(data)
    }
  }

  /*
    These fields should be available in the data layer at all times and pages and should be 
    updated whenever the dataLayer is updated e.g. on page load. 
  */
  const dataLoadedEvent = (
    dataLayerPageData: IDataLayerPageData,
    actionField?: IDataLayerActionField,
    products?: IDataLayerProduct[],
  ) => {
    const basketData = context?.basket
    const pageData = dataLayerPageData

    const cartDiscountCodes = basketData?.discountCodes
    const cartDiscountPrice = basketData?.price?.discountedPrice
    const isProductLevelDiscount = cartDiscountPrice && !cartDiscountCodes?.length
    const isCartLevelDiscount = cartDiscountPrice && cartDiscountCodes?.length

    // Set page data state
    setDataLayerPageData(pageData)
    
    const eventData: IDataLoadedEventData = {
      'event': 'dataLoaded',
      'user': {
        'loginStatus': isUserValid().toString().toUpperCase(),
        'customerId': getOid() ?? '',
        'homeGymid': getHomeGym() ?? '',
      },
      'page': {
        ...pageData,
        'environment': process.env.REACT_APP_ADOBE_LAUNCH_ENV ?? 'Err: variable not set',
        'language': 'English', // Always English
        'cartVolume': basketData?.numberOfItems,
      },
    }

    if (actionField && products) {
      eventData.ecommerce = {
        'currencyCode': 'GBP',
        'cartId': basketData?.basketId,
        'config': {
          actionField,
          products,
        },
      }

      // Add coupon(s) from cart
      if (cartDiscountCodes?.length) {
        actionField.coupon = cartDiscountCodes.join(', ')
      }

      // If product discount is applied
      if (isProductLevelDiscount && eventData.ecommerce.config?.actionField) {
        eventData.ecommerce.config.actionField.couponType = 'product discount'
      }

      // If discount code is applied
      if (isCartLevelDiscount && eventData.ecommerce.config?.actionField) {
        eventData.ecommerce.config.actionField.couponType = 'discount code'
      }
    }

    dataLayerPush(eventData)
  }

  // The product config event should fire for all the steps in the product config funnel. 
  const configEvent = (actionField: IDataLayerActionField, products: IDataLayerProduct[]) => {
    const eventData = {
      'event': 'config',
      'ecommerce': {
        'currencyCode': 'GBP',
        'config': {
          actionField,
          products,
        },
      },
    }

    dataLayerPush(eventData)
  }

  // Interactions with filters
  const filtersEvent = () => {
    const currentFiltersApplied = filtContext?.appliedFilters

    if (!currentFiltersApplied) {
      return
    }

    const filtersArr = Object.keys(currentFiltersApplied).map(filterCategory => {
      // Loop filters applied and create array for datalayer
      const filterCategoryArr = currentFiltersApplied[filterCategory as keyof IFiltersApplied]
        .map((filt) => {
          let filterValueName = filt

          // Substitute the level with trainer type text
          if (filt === 'senior' || filt === 'normal') {
            filterValueName = trainerTypes[filt]
          }

          return {
            type: filterCategory,
            details: filterValueName,
          }
        })
      
      return filterCategoryArr
    }).flat()

    const eventData = {
      'event': 'filter',
      'filter': filtersArr,
    }

    dataLayerPush(eventData)
  }

  // Interaction with the PT bio
  const ptBioEvent = (ptName: string) => {
    const eventData = {
      'event': 'ptBioClick',
      'pt': {
        'personalTrainerId': ptName,
        'personalTrainerLocation': context?.selectedGymData?.name,
      },
    }

    dataLayerPush(eventData)
  }

  // Checkout event (cart and payment steps)
  const checkoutEvent = (actionField: IDataLayerActionField) => {
    const cartData = context?.basket

    if (context?.basket?.discountCodes?.length) {
      actionField.coupon = context?.basket?.discountCodes.join(', ')
    }

    // Build product array from basket
    const products = cartData?.commerceItems.map(cartItem => {
      const productItem: IDataLayerProduct = {
        'serviceLine': 'Personal Trainer',
        'itemType': cartItem.item_type,
        'id': cartItem.commerceItemId,
        'name': cartItem.name,
        'price': cartItem.price.discountedPrice ?? cartItem.price.totalPrice,
        'variantId': cartItem.variantId?.toString(),
        'quantity': cartItem.quantity,
        'size': cartItem.sessionPackage.size,
        'location': cartItem.sessionPackage.location ?? 'No location set on product',
        'clubid': cartItem.sessionPackage.clubId ?? 'No club ID set on product',
        'personalTrainerId': cartItem.sessionPackage.personalTrainer,
      }

      return productItem
    })

    const eventData = {
      event: 'checkout',
      ecommerce: {
        currencyCode: 'GBP',
        cartId: cartData?.basketId,
        checkout: {
          actionField,
          add: {
            products,
          },
        },
      },
    }

    dataLayerPush(eventData)
  }
  
  const getCartItemsForDataLayer = () => {
    const cartData = context?.basket

    // Build product array from basket
    return cartData?.commerceItems.map(cartItem => {
      const productItem: IDataLayerProduct = {
        'serviceLine': 'Personal Trainer',
        'itemType': cartItem.item_type,
        'id': cartItem.commerceItemId,
        'name': cartItem.name,
        'price': cartItem.price.discountedPrice ?? cartItem.price.totalPrice,
        'variantId': cartItem.variantId?.toString(),
        'quantity': cartItem.quantity,
        'size': cartItem.sessionPackage.size,
        'location': cartItem.sessionPackage.location ?? 'No location set on product',
        'clubid': cartItem.sessionPackage.clubId ?? 'No club ID set on product',
        'personalTrainerId': cartItem.sessionPackage.personalTrainer,
      }

      return productItem
    })
  }
  
  return {
    dataLoadedEvent,
    dataLayerPageData,
    configEvent,
    filtersEvent,
    ptBioEvent,
    checkoutEvent,
    getCartItemsForDataLayer,
  }
}

export default useDataLayer
