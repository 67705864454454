import { useContext } from 'react'
import { filterContext } from '../components/PersonalTrainerLister/filterContext'
import { IFiltersApplied } from '../components/PersonalTrainerFilters/types'
import { removeItemFromArray } from '../utils/removeItemFromArray'

type TypePtAttributeBeingFiltered = keyof IFiltersApplied

const useFilters = () => {
  const filterContextData = useContext(filterContext)

  const removeFilter = (ptAttributeBeingFiltered: TypePtAttributeBeingFiltered, option: string) => {
    let filterCategoryData = filterContextData?.appliedFilters[ptAttributeBeingFiltered as keyof IFiltersApplied]
    const appliedFilters = filterContextData?.appliedFilters
    const appliedFiltersClone: any = { ...appliedFilters }

    // Prep object array to set in context data
    if (appliedFilters && filterCategoryData && filterCategoryData?.indexOf(option) > -1) {
      // If the filter is already applied, remove it.
      filterCategoryData = removeItemFromArray<string>(filterCategoryData, option)
      appliedFiltersClone[ptAttributeBeingFiltered as keyof IFiltersApplied] = removeItemFromArray<string>(filterCategoryData, option)
      
      // Create clone of the original state object and set as new state value
      filterContextData?.setAppliedFilters(appliedFiltersClone)
    }
  }

  const addFilter = (ptAttributeBeingFiltered: TypePtAttributeBeingFiltered, option: string) => {
    let filterCategoryData = filterContextData?.appliedFilters[ptAttributeBeingFiltered as keyof IFiltersApplied]
    const appliedFilters = filterContextData?.appliedFilters

    if (appliedFilters && filterCategoryData?.indexOf(option) === -1) {
      // If the filter is not applied, apply it.
      filterCategoryData?.push(option)  

      // Create clone of the original state object and set as new state value
      filterContextData?.setAppliedFilters({ ...appliedFilters })
    }
  }

  const addRemoveFilter = (ptAttributeBeingFiltered: TypePtAttributeBeingFiltered, option: string) => {
    let filterCategoryData = filterContextData?.appliedFilters[ptAttributeBeingFiltered as keyof IFiltersApplied]

    if (filterCategoryData && filterCategoryData?.indexOf(option) > -1) {
      removeFilter(ptAttributeBeingFiltered, option)
      return
    }

    if (filterCategoryData && filterCategoryData?.indexOf(option) === -1) {
      addFilter(ptAttributeBeingFiltered, option)
    }
  }

  const clearFilters = () => {
    filterContextData?.setAppliedFilters({
      level: [],
      specialities: [],
    })
  }

  /*
  * createFilterArray function: Create an object array of each filter option.
  * ptAttr: this is the filter category (i.e. level or specialities)
  * ptList: the list of PTs currently displayed (may be filtered)
  */
  const createFilterArray = (ptAttr: string, ptList: PersonalTrainerListDataType) => {
    /*
    * Extract PT attribute values (attributes being "level" or "specialities") into
    * an array, then flatten that array to get rid of any sub-arrays
    */
    const filterOptionArray = ptList?.map(pt => {
      return pt[ptAttr as keyof IPersonalTrainerData]
    }).flat(Infinity)

    // Remove duplicate attribute values
    const uniqueFilterOptions = Array.from(
      new Set(filterOptionArray),
    )

    /*
    * Create object array of filters with name and number of PT available with each filter
    * We use the array of filters including duplicates to count all of the instances of
    * each filter (i.e. how many times a value appears in the array).
    */
    const filtersWithCount = filterOptionArray?.map((filterStr, index, thisArray) => {
      if (typeof filterStr === 'string') {
        return {
          name: filterStr.toLowerCase(),
          count: thisArray.filter(filterItem => {
            return filterItem === filterStr
          }).length,
        }
      }

      return undefined
    })

    /*
    * Return an array of unique objects for each filter, with each filter's count
    */
    return uniqueFilterOptions?.map((filter) => {
      if (typeof filter !== 'string') {
        return undefined
      }
      
      const count = filtersWithCount?.find(filterObj => filterObj?.name === filter?.toLowerCase())?.count
      
      return {
        name: filter,
        count,
      }
    })
  }

  return {
    removeFilter,
    addFilter,
    addRemoveFilter,
    clearFilters,
    createFilterArray,
  }
}

export default useFilters