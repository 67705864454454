import LocationLister from './LocationLister'
import styles from './LocationList.module.scss'
import getDistanceBetweenPoints from '../../utils/getDistanceBetweenPoints'

interface IComponentProps {
  places: GymLocationDataListType;
  userCoords?: coordsType;
}

const LocationList = ({ places, userCoords }: IComponentProps) => {
  const placesWithDistance = places?.map(place => {
    const gymLat = place.latitude || '0'
    const gymLng = place.longitude || '0'

    const gymCoords = {
      lat: parseFloat(gymLat),
      lng: parseFloat(gymLng),
    }

    if (userCoords && gymCoords) {
      place.distance = getDistanceBetweenPoints(userCoords, gymCoords)
    }

    return place
  })
  
  const placeList = placesWithDistance?.sort((a, b) => {
    if (a?.distance && b?.distance) {
      return (a.distance > b.distance) ? 1 : -1
    }

    return -1
  })

  return (
    <div data-testid='location-list'>
      <h4>Showing the 3 closest gyms to your location search</h4>
      <div className={styles.LocationListContainer}>
        <LocationLister places={placeList}/>
      </div>
    </div>
  )
}

export default LocationList