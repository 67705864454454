import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import style from './LinkButton.module.scss'

interface IComponentProps {
  link: string;
  target?: string;
  children: ReactNode;
  isHref?: boolean;
  isButtonStyle?: boolean;
  buttonStyleType?: string;
  onClick?(): void;
  disabled?: boolean;
  testId?: string;
  className?: string; 
  isInline?: boolean;
}

const LinkButton = ({
  link,
  target,
  children,
  isHref = false,
  isButtonStyle = false,
  buttonStyleType = 'primary',
  onClick,
  disabled = false,
  testId,
  isInline = false,
  className = '',
}: IComponentProps) => {

  const styleClasses = `
    ${style.root} 
    ${isButtonStyle ? `${style.button} ${style[buttonStyleType]}` : ''}
    ${isInline? style.inlineFlex : ''} 
    ${disabled ? style.disabled : ''} 
    ${className}
  `

  const btnLink = disabled ? '' : link

  if (isHref) {
    return (
      <a
        className={styleClasses}
        href={btnLink}
        target={target}
        onClick={onClick}
        data-testid={testId}
      >
        {children}
      </a>
    )
  } else {
    return (
      <Link
        className={styleClasses}
        to={btnLink}
        onClick={onClick}    
        data-testid={testId}    
      >
        {children}
      </Link>
    )
  }

  
}

export default LinkButton