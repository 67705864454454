import {
  ChangeEvent,
  CSSProperties,
  HTMLInputTypeAttribute,
  ReactNode,
} from 'react'
import styles from './Checkbox.module.scss'
import { Checkmark } from '../icons'

interface IComponentProps {
  children?: ReactNode;
  id: string;
  label?: string;
  name: string;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  style?: CSSProperties;
  type?: HTMLInputTypeAttribute;
  value?: boolean | string;
  isError?: boolean;
  errorMsg?: string;
  checked?: boolean;
  testId?: string;
}

// TODO
const Checkbox = ({
  id,
  label,
  children,
  name,
  onBlur,
  onChange,
  placeholder,
  value,
  isError = false,
  errorMsg,
  checked,
  testId,
}: IComponentProps) => {

  const fieldProperties = {
    id,
    name,
    onBlur,
    onChange,
    placeholder,
  }
  const valueString = value?.toString()

  return (
    <div>
      <label className={styles.checkboxContainer}>
        <input 
          type='checkbox'
          className={styles.input}
          data-testid={testId}
          checked={checked ?? !!value}
          value={valueString}
          {...fieldProperties}
        />
        <span className={styles.checkmark}>
          <Checkmark className={styles.checkmarkIcon} width={15} height={15} />
        </span>
        {!children && 
          <span className={styles.labelText}>
            {label}
          </span>
        }
        {children}
      </label>
      {isError &&
        <div>{errorMsg}</div>
      }
    </div>
  )
}

export default Checkbox