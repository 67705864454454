import style from './Header.module.scss'
import { NuffieldShield } from '../icons'
import BackButton from './BackButton'
import ProgressBar from '../ProgressBar'
import { ReactNode, useEffect, useState, useRef } from 'react'

interface IComponentProps {
  title?: string;
  step?: number;
  hasBackButton?: boolean;
  customBackButton?: ReactNode;
}

const Header = ({
  title = 'Personal Training',
  step,
  hasBackButton = true,
  customBackButton,
}: IComponentProps) => {

  const [isScrolled, setIsScrolled] = useState(false)
  let throttleInProgress = useRef<boolean>()

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleScroll = () => {
    setIsScrolled(true)
    if (throttleInProgress.current) { return }
    throttleInProgress.current = true
    setTimeout(() => {
      if (window.scrollY > 0) {
        setIsScrolled(true)
      } else {
        setIsScrolled(false)
      }
      throttleInProgress.current = false
    }, 250)

  }

  return (
    <header className={`${style.root} ${isScrolled && style.isScrolled}`} data-testid='header'>
      <div className={style.headingWrapper}>
        <div className={style.logo}>
          <NuffieldShield width={30} height={30} />
        </div>
        <div className={style.titleWrapper}>
          <h1
            className={`${style.title} h2 noMargin`}
            data-testid='header-title'
          >{title}</h1>
          {hasBackButton &&
            <div className={style.back}>
              <BackButton />
            </div>
          }
          {customBackButton &&
            <div className={style.back}>{customBackButton}</div>
          }
        </div>
      </div>

      {step &&
        <ProgressBar step={step} isScrolled={isScrolled} />
      }
    </header>
  )
}

export default Header